import React, { useState } from "react";
import {
  ActionIcon,
  Button,
  Card,
  Grid,
  Select,
  Stack,
  Text,
  Loader,
  Title,
  Center,
  Skeleton,
} from "@mantine/core";
import { IconLockOpen, IconSparkles, IconWand } from "@tabler/icons-react";
import AIBrief from "../Brief/FreeBriefs/ai";
import Classes from "./index.module.scss";
import { useAuth } from "../../../AuthContext";

const AiBriefForm: React.FC = () => {
  const uxBriefs = ["ux", "uxresearch"];
  const getRandomUXBrief = () =>
    uxBriefs[Math.floor(Math.random() * uxBriefs.length)];
  const { user } = useAuth();

  const [formData, setFormData] = useState({
    projectType: getRandomUXBrief(),
    platform: "Web",
    projectSize: "medium",
    difficulty: "3",
    industry: "Technology",
  });

  const [brief, setBrief] = useState<any>(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (field: string) => (value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/briefs/generate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            project_type: formData.projectType,
            platform: formData.platform,
            type: "free", // Assuming "free" is a fixed value for now
            project_size: formData.projectSize,
            industry: formData.industry,
            difficulty: parseInt(formData.difficulty),
            brief_title: "", // Leave empty to let the AI generate it
            description: "", // Leave empty to let the AI generate it
          }),
        }
      );

      if (response.ok) {
        const { brief } = await response.json();
        setBrief(brief);
      } else {
        const errorData = await response.json();
        console.error("Server responded with error:", errorData);
        setError(errorData.message || "Failed to generate brief.");
      }
    } catch (error) {
      console.error("Error generating brief:", error);
      setError("Error generating brief.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderBrief = () => {
    return <AIBrief {...brief} />;
  };

  return (
    <>
      {!user?.stripeSubscriptionId ? (
        <Center>
          <Card w={"fit-content"} p="xl" shadow="lg" radius="xl" withBorder>
            <Stack mr="sm" ml={0} p="md" maw={600} align="center" ta="center">
              <IconLockOpen size={64} />
              <Title order={2}>
                Upgrade to Pro for only $5.99 to access AI generated briefs
              </Title>
              <Text size="xl">
                Access AI generated briefs and unlock more features by upgrading
                to Pro
              </Text>
              <Button
                onClick={() => (window.location.href = "/subscribe")}
                variant="gradient"
                size="lg"
                gradient={{ deg: 45, from: "orange.7", to: "red.8" }}
                leftSection={<IconSparkles size={16} />}>
                Upgrade to Pro
              </Button>
            </Stack>
          </Card>
        </Center>
      ) : (
        <form onSubmit={handleSubmit}>
          <Stack gap="lg">
            {error && <Text color="red">{error}</Text>}

            <Grid gutter="lg">
              <Grid.Col span={{ sm: 3, xs: 12 }}>
                <Card withBorder radius="lg" p="xl">
                  <Stack>
                    <Title order={4}>Brief Settings</Title>
                    <Stack gap={0}>
                      <Select
                        label="Project Type"
                        labelProps={{
                          mb: 8,
                          fw: 400,
                        }}
                        radius="lg"
                        variant="filled"
                        searchable
                        size="lg"
                        value={formData.projectType}
                        data={[
                          { value: "ux", label: "UX Design" },
                          { value: "uxresearch", label: "UX Research" },
                          { value: "ui", label: "UI Design" },
                          { value: "design", label: "Graphic Design" },
                          { value: "webdes", label: "Website Design" },
                          { value: "appdes", label: "App Design" },
                          { value: "branding", label: "Branding" },
                          { value: "marketing", label: "Marketing" },
                          { value: "socialmedia", label: "Social Media" },
                          { value: "content", label: "Content Writing" },
                          { value: "video", label: "Video Editing" },
                          { value: "photography", label: "Photography" },
                          { value: "illustration", label: "Illustration" },
                          { value: "animation", label: "Animation" },
                          { value: "motion", label: "Motion Graphics" },
                        ]}
                        placeholder="Project Type"
                        onChange={(value) =>
                          value && handleChange("projectType")(value)
                        }
                      />
                      <Select
                        label="Industry"
                        labelProps={{
                          mb: 4,
                          fw: 400,
                        }}
                        searchable
                        radius="lg"
                        variant="filled"
                        size="lg"
                        mt="md"
                        value={formData.industry}
                        data={[
                          { value: "Advertising", label: "Advertising" },
                          { value: "Architecture", label: "Architecture" },
                          { value: "Arts", label: "Arts & Culture" },
                          { value: "Automotive", label: "Automotive" },
                          { value: "Beauty", label: "Beauty & Cosmetics" },
                          { value: "Consumer Goods", label: "Consumer Goods" },
                          { value: "Education", label: "Education" },
                          { value: "Entertainment", label: "Entertainment" },
                          { value: "Fashion", label: "Fashion" },
                          { value: "Film & TV", label: "Film & TV" },
                          { value: "Finance", label: "Finance" },
                          {
                            value: "Food & Beverage",
                            label: "Food & Beverage",
                          },
                          { value: "Healthcare", label: "Healthcare" },
                          { value: "Hospitality", label: "Hospitality" },
                          {
                            value: "Interior Design",
                            label: "Interior Design",
                          },
                          { value: "Marketing", label: "Marketing" },
                          { value: "Media", label: "Media" },
                          { value: "Non-profit", label: "Non-profit" },
                          { value: "Packaging", label: "Packaging" },
                          { value: "Publishing", label: "Publishing" },
                          { value: "Real Estate", label: "Real Estate" },
                          { value: "Retail", label: "Retail" },
                          { value: "Sports", label: "Sports" },
                          { value: "Technology", label: "Technology" },
                          {
                            value: "Travel & Tourism",
                            label: "Travel & Tourism",
                          },
                          { value: "Video Games", label: "Video Games" },
                        ]}
                        placeholder="Industry"
                        onChange={(value) =>
                          value && handleChange("industry")(value)
                        }
                      />
                      <Select
                        label="Platform"
                        labelProps={{
                          mb: 4,
                          fw: 400,
                        }}
                        radius="lg"
                        variant="filled"
                        size="lg"
                        mt="md"
                        value={formData.platform}
                        data={[
                          { value: "Web", label: "Web" },
                          { value: "Mobile", label: "Mobile" },
                          { value: "Print", label: "Print" },
                        ]}
                        placeholder="Platform"
                        onChange={(value) =>
                          value && handleChange("platform")(value)
                        }
                      />
                      <Select
                        label="Project Size"
                        labelProps={{
                          mb: 4,
                          fw: 400,
                        }}
                        radius="lg"
                        variant="filled"
                        size="lg"
                        mt="md"
                        value={formData.projectSize}
                        data={[
                          { value: "small", label: "Small" },
                          { value: "medium", label: "Medium" },
                          { value: "large", label: "Large" },
                        ]}
                        placeholder="Project Size"
                        onChange={(value) =>
                          value && handleChange("projectSize")(value)
                        }
                      />
                      <Select
                        label="Difficulty"
                        labelProps={{
                          mb: 4,
                          fw: 400,
                        }}
                        radius="lg"
                        variant="filled"
                        size="lg"
                        mt="md"
                        value={formData.difficulty}
                        data={[
                          { value: "1", label: "1 - Easy" },
                          { value: "2", label: "2 - Intermediate" },
                          { value: "3", label: "3 - Moderate" },
                          { value: "4", label: "4 - Hard" },
                          { value: "5", label: "5 - Very Hard" },
                        ]}
                        placeholder="Difficulty"
                        onChange={(value) =>
                          value && handleChange("difficulty")(value)
                        }
                      />
                    </Stack>
                    <Button
                      leftSection={
                        isLoading ? (
                          <Loader size="xs" />
                        ) : (
                          <IconWand size={20} stroke={1.45} />
                        )
                      }
                      type="submit"
                      disabled={isLoading}
                      size="lg"
                      fullWidth
                      radius={"xl"}
                      variant="gradient"
                      gradient={{ from: "grape", to: "indigo" }}>
                      {isLoading ? "Generating..." : "Generate Brief"}
                    </Button>
                  </Stack>
                </Card>
              </Grid.Col>
              <Grid.Col span={{ sm: 9, xs: 12 }}>
                {brief ? (
                  <>
                    {isLoading ? (
                      <Center w={"100%"}>
                        <Card h={600} w={"100%"} radius="lg" withBorder p="xl">
                          <Skeleton h={"100%"} w={"100%"}></Skeleton>
                        </Card>
                      </Center>
                    ) : (
                      <Card w={"100%"} radius="lg" withBorder p="xl">
                        <Stack>{renderBrief()}</Stack>
                      </Card>
                    )}
                  </>
                ) : (
                  <Card w={"100%"} radius="lg" withBorder p="xl">
                    <Stack align="center" justify="center" h={300}>
                      <ActionIcon
                        className={Classes.ActionIcon}
                        size={82}
                        radius={100}
                        variant="light"
                        onClick={handleSubmit}
                        disabled={isLoading}>
                        <IconWand stroke={1} size={46} />
                      </ActionIcon>
                      <Text size="lg">
                        {isLoading
                          ? "Generating brief..."
                          : "Generate a random brief"}
                      </Text>
                    </Stack>
                  </Card>
                )}
              </Grid.Col>
            </Grid>
          </Stack>
        </form>
      )}
    </>
  );
};

export default AiBriefForm;
