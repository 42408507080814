import React, { useState, useEffect } from "react";
import {
  Center,
  Grid,
  Pagination,
  Select,
  TextInput,
  Title,
  Loader,
  Alert,
} from "@mantine/core";
import PostsCard from "../../../components/BriefGenerator/Socials/Cards";
import { IconSearch } from "@tabler/icons-react";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import { Post } from "../../../types/types"; // Import your interfaces

const SocialFeed: React.FC = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [projectType, setProjectType] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const { user } = useAuth(); // Get the current logged-in user

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/posts`,
          {
            params: {
              search,
              sortBy,
              sortOrder,
              projectType,
              page,
              limit: 16,
            },
          }
        );

        const postsData: Post[] = response.data.posts;

        // Determine if each post is liked by the current user
        const postsWithLikeStatus = postsData.map((post) => ({
          ...post,
          likeStatus: user ? post.likedBy.includes(user._id) : false, // Determine if the post is liked by the user
        }));

        setPosts(postsWithLikeStatus);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setError("Failed to fetch posts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [search, sortBy, sortOrder, projectType, page, user]);

  return (
    <Grid gutter="xl">
      <Grid.Col span={12}>
        <Grid>
          <Grid.Col span={6}>
            <Title order={2} fw={500}>
              Community Posts
            </Title>
          </Grid.Col>
          <Grid.Col span={"auto"}>
            <TextInput
              size="md"
              radius={"lg"}
              placeholder="Search"
              leftSection={<IconSearch size={16} />}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid.Col>

          <Grid.Col span={"auto"}>
            <Select
              radius={"lg"}
              size="md"
              placeholder="Project Type"
              data={[
                { value: "", label: "All" },
                { value: "Art", label: "Art" },
                { value: "UX Design", label: "UX Design" },
                { value: "UI Design", label: "UI Design" },
              ]}
              value={projectType}
              onChange={(value) => setProjectType(value || "")}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>

      {loading ? (
        <Center w={"100%"} h={600}>
          <Loader size="lg" />
        </Center>
      ) : error ? (
        <Grid.Col span={12}>
          <Alert title="Error" color="red">
            {error}
          </Alert>
        </Grid.Col>
      ) : (
        posts.map((post) => (
          <Grid.Col
            span={{
              xs: 6,
              sm: 6,
              md: 4,
              lg: 3,
            }}
            key={post._id}>
            <PostsCard
              {...post} // Spread all post properties directly
            />
          </Grid.Col>
        ))
      )}

      {!loading && !error && (
        <Grid.Col span={12}>
          <Center mt="lg">
            <Pagination
              total={totalPages}
              onChange={(newPage) => setPage(newPage)}
            />
          </Center>
        </Grid.Col>
      )}
    </Grid>
  );
};

export default SocialFeed;
