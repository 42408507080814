import "./App.css";
import "@mantine/core/styles.css";
import {
  AppShell,
  Group,
  Burger,
  Image,
  MantineProvider,
  Button,
  Anchor,
  ActionIcon,
  Grid,
  Menu,
  Card,
  Stack,
  Divider,
  Switch,
  Text,
  Indicator,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import "@mantine/dropzone/styles.css";
import customTheme from "./_app";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./pages";
import { IconPlus, IconSparkles } from "@tabler/icons-react";
import SocialFeed from "./pages/Social/Feed";
import Subscribe from "./pages/Account/Subscribe";
import LoginForm from "./pages/Account/Logins/Login";
import RegisterForm from "./pages/Account/Logins/Register";
import ProfilePage from "./pages/Account/ProfilePage";
import { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import UserAvatar from "./components/User/Avatar";
import PublicProfile from "./components/Profile";
import PostPage from "./components/Posts/PostPage";
import BriefCollectionPage from "./pages/Briefs";
import CreatePostModal from "./components/Posts/CreatePost";
import ThankYouPage from "./pages/Subscription/ThankYou";
import AccountSettings from "./pages/Account/Settings";
import UploadBriefForm from "./pages/Private";
import PremiumUX from "./components/Briefs/PremiumUX";
import FreeBriefCollectionPage from "./pages/Briefs/free";
import PremiumBriefSinglePage from "./pages/Briefs/SinglePage/premium";
import RegisterPage from "./pages/Account/Register";
import RequestPasswordReset from "./pages/Account/Logins/ResetRequest";
import ResetPassword from "./pages/Account/Logins/SetPassword";
import TermsAndConditions from "./pages/Legal/Terms";
import PrivacyPolicy from "./pages/Legal/Privacy";
import CookiePolicy from "./pages/Legal/Cookies";
import { Helmet } from "react-helmet";
import AiBriefForm from "./components/BriefGenerator/AiForm";
import AIPage from "./pages/AI";

function App() {
  const { user, logout, loading } = useAuth();
  const [opened, { toggle }] = useDisclosure();
  const [burgerOpened, { toggle: toggleBurger }] = useDisclosure();
  const [createPostOpened, setCreatePostOpened] = useState(false); // Separate state
  const [colorScheme, setColorScheme] = useState<"light" | "dark">("light"); // Initialize with 'light' or 'dark'

  const handleCreatePostToggle = () => setCreatePostOpened((prev) => !prev);

  // Load the color scheme from localStorage when the app starts
  useEffect(() => {
    const savedColorScheme = localStorage.getItem("colorScheme") as
      | "light"
      | "dark";
    if (savedColorScheme) {
      setColorScheme(savedColorScheme);
    }
  }, []);

  // Toggle the color scheme and save the user's choice in localStorage
  const toggleColorScheme = () => {
    const newColorScheme = colorScheme === "light" ? "dark" : "light";
    setColorScheme(newColorScheme);
    localStorage.setItem("colorScheme", newColorScheme); // Save the preference in localStorage
    document.documentElement.setAttribute("data-theme", newColorScheme); // Apply the theme immediately
  };

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = "/"; // Redirect to home page after logout
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <MantineProvider theme={customTheme} forceColorScheme={colorScheme}>
      <BrowserRouter>
        {/* <CreatePostModal opened={opened} onClose={toggle} /> */}
        <AppShell
          header={{ height: 72 }}
          footer={{
            height: 100,
          }}
          navbar={{
            width: 300,
            breakpoint: "sm",
            collapsed: { desktop: true, mobile: !opened },
          }}>
          <AppShell.Header>
            <Grid align="center" px="lg" visibleFrom="sm">
              <Grid.Col span={8}>
                <Group h={72} wrap="nowrap" gap={"xl"}>
                  <Image
                    onClick={() => (window.location.href = "/")}
                    height={32}
                    src={
                      colorScheme === "light"
                        ? "../darklogo.svg"
                        : "../logo.svg"
                    }
                    mr={"lg"}
                  />
                  <Anchor
                    c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                    href="/"
                    size="md"
                    fw="600"
                    underline="never">
                    Home
                  </Anchor>
                  <Anchor
                    c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                    href="/briefs"
                    size="lg"
                    fw="600"
                    underline="never">
                    Briefs
                  </Anchor>
                  <Anchor
                    style={{
                      wrap: "nowrap",
                    }}
                    c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                    href="/posts"
                    size="lg"
                    fw="600"
                    underline="never">
                    Posts
                  </Anchor>
                  <Group>
                    <Anchor
                      c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                      href="/ai"
                      size="lg"
                      fw="600"
                      underline="never">
                      <Indicator color="red" offset={0} label="New!" />
                      AI Generator
                    </Anchor>
                  </Group>
                </Group>
              </Grid.Col>
              <Grid.Col span={4}>
                <Group
                  h={72}
                  justify="right"
                  wrap="nowrap"
                  gap={"md"}
                  visibleFrom="sm">
                  {user ? (
                    <Group gap="md">
                      <Menu
                        closeOnItemClick={false}
                        width={"180"}
                        position="bottom-end">
                        <Menu.Target>
                          <Card
                            style={{
                              cursor: "pointer",
                            }}
                            py="xs"
                            radius="xl"
                            px="sm"
                            withBorder>
                            <Group>
                              <UserAvatar
                                textColor={
                                  colorScheme === "dark" ? "#f4f4f5" : "dark"
                                }
                                username={user?.username}
                              />
                            </Group>
                          </Card>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Item
                            my={6}
                            py={"xs"}
                            onClick={() =>
                              (window.location.href = `/profile/${user.username}`)
                            }>
                            Profile
                          </Menu.Item>
                          <Menu.Item
                            my={6}
                            py={"xs"}
                            onClick={() =>
                              (window.location.href = `/profile/settings`)
                            }>
                            Account Settings
                          </Menu.Item>
                          <Menu.Item my={6} py={"xs"} bg="#ffffff10">
                            <Switch
                              label={
                                colorScheme === "light"
                                  ? "Light Mode"
                                  : "Dark Mode"
                              }
                              checked={colorScheme === "dark"}
                              onChange={toggleColorScheme}
                            />
                          </Menu.Item>
                          <Divider mt="md" />
                          <Menu.Item c="red" mt={4} onClick={handleLogout}>
                            {" "}
                            Logout
                          </Menu.Item>
                        </Menu.Dropdown>
                      </Menu>
                      <Button
                        visibleFrom="lg"
                        leftSection={<IconPlus size={18} />}
                        size="md"
                        c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                        variant="outline"
                        onClick={handleCreatePostToggle}>
                        Create Post
                      </Button>
                      <ActionIcon
                        hiddenFrom="lg"
                        size="xl"
                        radius="xl"
                        onClick={handleCreatePostToggle}>
                        <IconPlus size={24} />
                      </ActionIcon>

                      {/* <CreatePostModal
                        opened={noTransitionOpened}
                        onClose={() => setNoTransitionOpened(false)}
                      /> */}
                    </Group>
                  ) : (
                    <Group gap="md">
                      <Anchor
                        c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                        href="/login"
                        size="lg"
                        fw="600"
                        underline="never">
                        Login
                      </Anchor>
                      <Anchor
                        c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                        href="/register"
                        size="lg"
                        fw="600"
                        underline="never">
                        Register
                      </Anchor>
                    </Group>
                  )}
                  {!user?.stripeSubscriptionId ? (
                    <Button
                      onClick={() => (window.location.href = "/subscribe")}
                      variant="gradient"
                      size="md"
                      gradient={{ deg: 45, from: "orange.7", to: "red.8" }}
                      leftSection={<IconSparkles size={16} />}>
                      Go Pro
                    </Button>
                  ) : (
                    <></>
                  )}
                </Group>
              </Grid.Col>
            </Grid>
            <Group
              h={72}
              justify="space-between"
              align="center"
              px="lg"
              hiddenFrom="sm">
              <Image
                onClick={() => (window.location.href = "/")}
                height={32}
                src={
                  colorScheme === "light" ? "../darklogo.svg" : "../logo.svg"
                }
              />

              <Burger
                opened={opened}
                onClick={toggle}
                aria-label="Toggle navigation"
              />
            </Group>
          </AppShell.Header>

          <AppShell.Navbar p={"xl"} h={"100vh"}>
            <Stack gap={"xl"}>
              <Anchor
                c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                href="/"
                size="28px"
                fw="600"
                underline="never">
                Home
              </Anchor>
              <Anchor
                c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                href="/briefs"
                size="28px"
                fw="600"
                underline="never">
                Briefs
              </Anchor>
              <Group>
                <Anchor
                  c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                  href="/ai"
                  size="lg"
                  fw="600"
                  underline="never">
                  <Indicator color="red" offset={0} label="New!" />
                  AI Generator
                </Anchor>
              </Group>
              <Anchor
                c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                href="/posts"
                size="28px"
                fw="600"
                underline="never">
                Posts
              </Anchor>
              <Divider />
              {user ? (
                <Stack gap="xl">
                  <Text c="dimmed">Your Account</Text>
                  <Anchor
                    c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                    onClick={() =>
                      (window.location.href = `/profile/${user.username}`)
                    }
                    size="16px"
                    fw="500"
                    underline="never">
                    Profile
                  </Anchor>
                  <Anchor
                    c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                    onClick={() => (window.location.href = `/profile/settings`)}
                    size="16px"
                    fw="500"
                    underline="never">
                    Settings
                  </Anchor>
                  <Anchor
                    c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                    onClick={handleLogout}
                    size="16px"
                    fw="500"
                    underline="never">
                    Logout
                  </Anchor>
                </Stack>
              ) : (
                <Stack gap="xl">
                  <Anchor
                    c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                    href="/login"
                    size="28px"
                    fw="600"
                    underline="never">
                    Login
                  </Anchor>

                  <Anchor
                    c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                    href="/register"
                    size="28px"
                    fw="600"
                    underline="never">
                    Create Free Account
                  </Anchor>
                </Stack>
              )}
              {!user?.stripeSubscriptionId ? (
                <Button
                  onClick={() => (window.location.href = "/subscribe")}
                  variant="gradient"
                  size="xl"
                  gradient={{ deg: 45, from: "orange.7", to: "red.8" }}
                  leftSection={<IconSparkles size={16} />}>
                  Go Pro
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </AppShell.Navbar>

          <AppShell.Main>
            {colorScheme === "light" && (
              <Image
                opacity={0.85}
                src="./rainbow.svg"
                pos="absolute"
                top={0}
                left={0}
                style={{
                  zIndex: -1,
                }}
              />
            )}
            <Stack px={"lg"} py={"xl"} w="100%" align="stretch">
              <CreatePostModal
                opened={createPostOpened}
                onClose={handleCreatePostToggle}
              />
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/ai" element={<AIPage />} />
                <Route path="/posts" element={<SocialFeed />} />
                <Route path="/subscribe" element={<Subscribe />} />
                <Route path="/login" element={<LoginForm />} />
                <Route path="/register" element={<RegisterPage />} />

                <Route
                  path="/request-password-reset"
                  element={<RequestPasswordReset />}
                />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
                <Route path="/profile/author" element={<ProfilePage />} />
                <Route path="/profile/:username" element={<PublicProfile />} />
                <Route path="/post/:postId" element={<PostPage />} />
                {user?.stripeSubscriptionId ? (
                  <Route path="/briefs" element={<BriefCollectionPage />} />
                ) : (
                  <Route path="/briefs" element={<FreeBriefCollectionPage />} />
                )}

                <Route path="/success" element={<ThankYouPage />} />
                <Route path="/profile/settings" element={<AccountSettings />} />
                <Route path="/upload" element={<UploadBriefForm />} />
                <Route path="/ux/pro/:briefId" element={<PremiumUX />} />
                <Route
                  path="/pro/:briefId"
                  element={<PremiumBriefSinglePage />}
                />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/cookies" element={<CookiePolicy />} />
                <Route path="/cancel" element={<AccountSettings />} />
                <Route path="*" element={<div>Not Found</div>} />
              </Routes>
            </Stack>
          </AppShell.Main>
          <AppShell.Section p="xl">
            <Card withBorder>
              <Grid>
                <Grid.Col span={{ lg: 6, sm: 12 }}>
                  <Text size="lg">
                    © 2024 DesignBrief.io. All rights reserved.
                  </Text>
                </Grid.Col>
                <Grid.Col span={{ lg: 6, sm: 12 }}>
                  <Group justify="end">
                    <Anchor
                      c={colorScheme === "dark" ? "#f4f4f5" : "dark"}
                      href="/terms"
                      size="md">
                      Terms & Conditions
                    </Anchor>
                    <Anchor
                      href="/privacy"
                      size="md"
                      c={colorScheme === "dark" ? "#f4f4f5" : "dark"}>
                      Privacy Policy
                    </Anchor>
                    <Anchor
                      href="/cookies"
                      size="md"
                      c={colorScheme === "dark" ? "#f4f4f5" : "dark"}>
                      Cookies
                    </Anchor>
                  </Group>
                </Grid.Col>
              </Grid>
            </Card>
          </AppShell.Section>
        </AppShell>
      </BrowserRouter>
    </MantineProvider>
  );
}

export default App;
