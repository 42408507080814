import {
  Container,
  Center,
  Card,
  Stack,
  Title,
  Text,
  Anchor,
} from "@mantine/core";
import RegisterForm from "../Logins/Register";

const RegisterPage = () => {
  return (
    <Container
      h={"calc(100vh - 200px)"}
      maw={{
        sm: "100%",
        md: 600,
      }}
    >
      <Center h="100%">
        <Card radius="lg" withBorder>
          <Stack h={"00%"}>
            <Stack gap={6}>
              <Title order={2}>Create a Free Account</Title>
              <Anchor href="/login">Already have an account? Log in</Anchor>
            </Stack>

            <RegisterForm />
          </Stack>
        </Card>
      </Center>
    </Container>
  );
};

export default RegisterPage;
