import { Text, Title, Container, Stack } from "@mantine/core";

function PrivacyPolicy() {
  return (
    <Container>
      <Stack>
        <Title order={1}>Privacy Policy</Title>
        <Text>Last Updated: 19 August, 2024</Text>

        <Text>
          At DesignBrief.io, we respect your privacy and are committed to
          protecting the personal information you share with us. This Privacy
          Policy explains how we collect, use, and share information when you
          use our website and services.
        </Text>

        <Title order={3} mt="md">
          1. Information We Collect
        </Title>
        <Text>
          We collect various types of information in connection with the
          services we provide, including:
        </Text>
        <Text>
          <strong>Personal Information:</strong> When you create an account,
          subscribe to our services, or post content, we may collect personal
          information such as your name, email address, and payment details.
        </Text>
        <Text>
          <strong>Usage Data:</strong> We collect information about your
          interactions with our site, such as your IP address, browser type,
          pages viewed, and time spent on our site. This data is collected
          through cookies and similar tracking technologies.
        </Text>

        <Title order={3} mt="md">
          2. How We Use Your Information
        </Title>
        <Text>We use the information we collect to:</Text>
        <Text>- Provide and maintain our services</Text>
        <Text>- Process your transactions and manage your subscriptions</Text>
        <Text>- Improve and personalize your experience on our site</Text>
        <Text>
          - Communicate with you, including sending you updates, offers, and
          support
        </Text>
        <Text>
          - Analyze how our services are used to improve performance and develop
          new features
        </Text>

        <Title order={3} mt="md">
          3. Use of Google Analytics and Microsoft Clarity
        </Title>
        <Text>
          We use Google Analytics and Microsoft Clarity to help us understand
          how users interact with our website. These tools collect information
          such as how often users visit the site, what pages they visit, and
          what other sites they used before coming to our site.
        </Text>
        <Text>
          <strong>Google Analytics:</strong> Google Analytics collects
          anonymized data to provide insights into website traffic and usage
          patterns. Google Analytics may use cookies to collect this data. You
          can learn more about how Google collects and processes data by
          visiting Google's Privacy Policy:{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer">
            https://policies.google.com/privacy
          </a>
          .
        </Text>
        <Text>
          <strong>Microsoft Clarity:</strong> Microsoft Clarity records user
          interactions on our site, such as mouse movements, clicks, and
          scrolls, to help us improve user experience. Clarity collects
          anonymized data and does not collect personal information. You can
          learn more about Microsoft Clarity's privacy practices here:{" "}
          <a
            href="https://privacy.microsoft.com/en-us/privacystatement"
            target="_blank"
            rel="noopener noreferrer">
            https://privacy.microsoft.com/en-us/privacystatement
          </a>
          .
        </Text>
        <Text>
          By using our website, you consent to the processing of data by Google
          Analytics and Microsoft Clarity in the manner described above.
        </Text>

        <Title order={3} mt="md">
          4. Sharing of Information
        </Title>
        <Text>
          We do not sell or rent your personal information to third parties. We
          may share your information with third-party service providers who
          assist us in operating our website, conducting our business, or
          servicing you, so long as those parties agree to keep this information
          confidential.
        </Text>
        <Text>
          We may also share your information if required by law, to protect our
          rights, or to comply with a judicial proceeding, court order, or legal
          process.
        </Text>

        <Title order={3} mt="md">
          5. Data Security
        </Title>
        <Text>
          We implement appropriate technical and organizational measures to
          protect your personal information from unauthorized access,
          disclosure, alteration, or destruction. However, no method of
          transmission over the internet or method of electronic storage is 100%
          secure, and we cannot guarantee absolute security.
        </Text>

        <Title order={3} mt="md">
          6. Your Choices
        </Title>
        <Text>
          You can choose not to provide certain information or opt out of
          certain communications, but this may limit your ability to use some
          features of our site. You may also manage your cookie preferences
          through your browser settings.
        </Text>

        <Title order={3} mt="md">
          7. Changes to This Privacy Policy
        </Title>
        <Text>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any material changes by posting the new
          Privacy Policy on our site and updating the "Last Updated" date.
        </Text>

        <Title order={3} mt="md">
          8. Contact Us
        </Title>
        <Text>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at{" "}
          <a href="mailto:contact@designbrief.io">contact@designbrief.io</a>.
        </Text>
      </Stack>
    </Container>
  );
}

export default PrivacyPolicy;
