import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Stack,
  Text,
  Group,
  Center,
  Loader,
  Button,
  Divider,
  Rating,
  Title,
  Container,
  Paper,
  ThemeIcon,
  Spoiler,
  Grid,
  Space,
  Flex,
  Card,
  Blockquote,
  Timeline,
  List,
} from "@mantine/core";
import {
  IconBook,
  IconTarget,
  IconBulb,
  IconChecklist,
  IconUser,
  IconGitBranch,
  IconCircle,
} from "@tabler/icons-react";
import { Brief } from "../../../types/types"; // Adjust the path to your types

const PremiumBriefSinglePage: React.FC = () => {
  const { briefId } = useParams<{ briefId: string }>(); // Use useParams to get briefId
  const [brief, setBrief] = useState<Brief | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBrief = async () => {
      console.log("Fetching brief with ID:", briefId); // Debugging log
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/briefs/premium/${briefId}`,
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const briefData = await response.json();
          setBrief(briefData);
        } else {
          const errorData = await response.json();
          console.error("Error data from backend:", errorData); // Log the error
          setError(errorData.message || "Failed to fetch the brief.");
        }
      } catch (error) {
        console.error("Error fetching brief:", error);
        setError("Error fetching the brief.");
      } finally {
        setLoading(false);
      }
    };

    fetchBrief();
  }, [briefId]);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Center>
        <Text color="red">{error}</Text>
      </Center>
    );
  }

  if (!brief) {
    return (
      <Center>
        <Text>No brief found.</Text>
      </Center>
    );
  }

  return (
    <Container>
      <Center>
        <Card radius="lg" withBorder>
          <Stack>
            <Group align="start" justify="space-between">
              <Stack gap="xs">
                <Title order={3}>{brief.brief_title}</Title>
                <Group>
                  <Text>Difficulty </Text>
                  <Rating color="yellow.5" value={brief.difficulty} readOnly />
                </Group>
              </Stack>
            </Group>

            <Group gap="xs">
              <Badge tt="capitalize" color="cyan" radius="sm" size="md">
                {brief.industry}
              </Badge>
              <Badge tt="capitalize" color="orange" radius="sm" size="md">
                {brief.platform}
              </Badge>

              <Badge
                tt="capitalize"
                color={
                  brief.project_size === "large"
                    ? "red"
                    : brief.project_size === "medium"
                    ? "yellow"
                    : "green"
                }
                radius="sm"
                size="md">
                {brief.project_size}
              </Badge>
            </Group>
            <Divider my="md" />
            <Stack gap={8}>
              <Title order={4}>Brief:</Title>
              <Text
                size="md"
                lh={"190%"}
                dangerouslySetInnerHTML={{ __html: brief.description }}
              />
            </Stack>
          </Stack>
        </Card>
      </Center>
    </Container>
  );
};

export default PremiumBriefSinglePage;
