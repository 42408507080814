import React from "react";
import { Group, Avatar, Anchor, Text } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";

interface UserAvatarProps {
  username: string;
  fullWidth?: boolean;
  textColor?: any;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  username,
  textColor,
  fullWidth,
}) => {
  return (
    <Group w={fullWidth ? "100%" : "auto"} gap={8}>
      <Avatar size="sm">{username[0].toUpperCase()}</Avatar>
      <Text visibleFrom="md" c={textColor}>
        {username}
      </Text>
      <IconChevronDown size={18} />
    </Group>
  );
};

export default UserAvatar;
