import React, { useState } from "react";
import {
  Anchor,
  Button,
  Card,
  Center,
  Container,
  Grid,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconCreativeCommons, IconUserPlus } from "@tabler/icons-react";
import { Helmet } from "react-helmet";

const RegisterForm: React.FC = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (field: string) => (value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const user = await response.json();
        setSuccess("Account created! Redirecting to login page...");
        // Redirect to the login page after successful registration
        setTimeout(() => {
          window.location.href = "/login";
        }, 1200);
      } else {
        const errorData = await response.json();
        console.error("Server responded with error:", errorData);
        setError(errorData.message || "Failed to register.");
      }
    } catch (error) {
      console.error("Error registering:", error);
      setError("Error registering.");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DesignBrief.io - Register Account</title>
        <meta
          name="description"
          content="Hone your design skills with DesignBrief.io. Generate practice briefs for UI/UX, web design, animation, and more. Perfect for students, professionals, and teams looking to sharpen their skills."
        />
        <link rel="canonical" href="https://www.designbrief.io/register" />

        <meta
          property="og:title"
          content="DesignBrief.io - Practice Design Briefs for UI, UX, Animation & More"
        />
        <meta
          property="og:description"
          content="Create and practice with realistic design briefs tailored for UI/UX, web design, animation, and more. Improve your design skills with DesignBrief.io."
        />
        <meta property="og:url" content="https://www.designbrief.io/register" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.designbrief.io/static/designbrief-social-preview.jpg"
        />
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Grid gutter={"md"}>
          <Grid.Col span={12}>
            <TextInput
              label="Display Name"
              labelProps={{
                mb: 12,
              }}
              size="md"
              placeholder="Display Name"
              required
              onChange={(e) => handleChange("username")(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Email Address"
              labelProps={{
                mb: 12,
              }}
              size="md"
              placeholder="Email"
              required
              onChange={(e) => handleChange("email")(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <PasswordInput
              label="Password"
              labelProps={{
                mb: 12,
              }}
              size="md"
              type="password"
              placeholder="Password"
              required
              onChange={(e) => handleChange("password")(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <Button
              size="lg"
              variant="gradient"
              gradient={{
                from: "grape",
                to: "violet",
              }}
              type="submit"
              fullWidth>
              Create Account
            </Button>
          </Grid.Col>
        </Grid>
        {error && <Text color="red">{error}</Text>}
        {success && <Text color="green">{success}</Text>}
      </form>
    </>
  );
};

export default RegisterForm;
