import React, { useState } from "react";
import {
  Anchor,
  Button,
  Card,
  Center,
  Container,
  Grid,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import { IconLock } from "@tabler/icons-react";

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setMessage("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/reset-password/${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password }),
        }
      );

      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      console.error("Error resetting password:", error);
      setMessage("Error resetting password.");
    }
  };

  return (
    <Container
      h={"calc(100vh - 200px)"}
      w={{
        sm: "100%",
        md: 600,
      }}>
      <Center h="100%">
        <Card radius="lg" withBorder>
          <Stack h={"00%"}>
            <Stack gap={6}>
              <Title order={2}>Reset Your Password</Title>
              <Text size="lg" c="dimmed">
                Enter a new password to reset your account
              </Text>
            </Stack>
            <form onSubmit={handleSubmit}>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    size="lg"
                    type="password"
                    placeholder="New Password"
                    required
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Button
                    leftSection={<IconLock stroke={1} />}
                    type="submit"
                    size="lg"
                    variant="gradient"
                    gradient={{ from: "grape", to: "indigo" }}
                    fullWidth>
                    Reset Password
                  </Button>
                </Grid.Col>
              </Grid>
              {message && <Text color="green">{message}</Text>}
            </form>
            <Anchor c={"dimmed"} href="/login">
              Remember your password? Log in here
            </Anchor>
          </Stack>
        </Card>
      </Center>
    </Container>
  );
};

export default ResetPassword;
