import {
  Accordion,
  Alert,
  Badge,
  Card,
  Center,
  Container,
  Divider,
  Grid,
  Loader,
  Pagination,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import BriefForm from "../components/BriefGenerator/Form";
import { useEffect, useState } from "react";
import { Post } from "../types/types";
import { useAuth } from "../AuthContext";
import axios from "axios";
import PostsCard from "../components/BriefGenerator/Socials/Cards";
import { IconPlus } from "@tabler/icons-react";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [projectType, setProjectType] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const { user } = useAuth(); // Get the current logged-in user

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/posts`,
          {
            params: {
              search,
              sortBy,
              sortOrder,
              projectType,
              page,
              limit: 16,
            },
          }
        );

        const postsData: Post[] = response.data.posts;

        // Determine if each post is liked by the current user
        const postsWithLikeStatus = postsData.map((post) => ({
          ...post,
          likeStatus: user ? post.likedBy.includes(user._id) : false, // Determine if the post is liked by the user
        }));

        setPosts(postsWithLikeStatus);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setError("Failed to fetch posts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [search, sortBy, sortOrder, projectType, page, user]);

  return (
    <Stack gap={"xl"} py={40}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          DesignBrief.io - Design Briefs for UI, UX, Graphic Design, Animation &
          More
        </title>
        <meta
          name="description"
          content="Hone your design skills with DesignBrief.io. Generate practice briefs for UI/UX, web design, animation, and more. Perfect for students, professionals, and teams looking to sharpen their skills."
        />
        <link rel="canonical" href="https://www.designbrief.io" />

        <meta
          property="og:title"
          content="DesignBrief.io - Practice Design Briefs for UI, UX, Animation & More"
        />
        <meta
          property="og:description"
          content="Create and practice with realistic design briefs tailored for UI/UX, web design, animation, and more. Improve your design skills with DesignBrief.io."
        />
        <meta property="og:url" content="https://www.designbrief.io" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.designbrief.io/static/designbrief-social-preview.jpg"
        />
      </Helmet>
      <Container size="62rem" w={"100%"}>
        <Stack gap={64} w={"100%"}>
          <Stack w={"100%"} gap={32}>
            <Stack w="100%" align="stretch" gap={64}>
              <Stack w={"100%"} align="center" ta="center">
                <Badge
                  variant="gradient"
                  gradient={{ from: "red.6", to: "orange.4" }}
                  radius="xl"
                  fw={500}
                  lts={1.0}
                  size="lg">
                  Realistic Design Brief Generator
                </Badge>
                <Title size={"46px"}>Generate a random design brief</Title>
                <Text size="lg">
                  Generate realistic design briefs for UI/UX, web design,
                  graphic design, and more. Perfect for students, professionals,
                  and teams looking to sharpen their skills.
                </Text>
              </Stack>
              <BriefForm />
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <Stack gap="xl" align="center" ta="center">
        {/* <Title order={2}>Recent Posts</Title>
        <Divider w={300} /> */}

        {/* <Grid w={"100%"} gutter="xl">
            {loading ? (
              <Center w={"100%"} h={600}>
                <Loader size="lg" />
              </Center>
            ) : error ? (
              <Grid.Col span={12}>
                <Alert title="Error" color="red">
                  {error}
                </Alert>
              </Grid.Col>
            ) : (
              posts.slice(0 - 3).map((post) => (
                <Grid.Col
                  span={{
                    xs: 6,
                    sm: 6,
                    md: 4,
                    lg: 3,
                    xl: 3,
                  }}
                  key={post._id}>
                  <PostsCard {...post} />
                </Grid.Col>
              ))
            )}
          </Grid> */}
        <Container mt="xl" w="100%" size="62rem">
          <Card withBorder radius="xl" p="xl">
            <Stack
              ta="left"
              p={{
                md: "sm",
                lg: "xl",
              }}
              gap="xl">
              <Stack gap="xs">
                <Title fw={550} mb="sm">
                  What is DesignBrief?
                </Title>
                <Text size="lg" fw={500}>
                  DesignBrief is Your Creative Playground for UX/UI, Design,
                  Animation, and More
                </Text>
              </Stack>

              <Text lh="190%">
                At DesignBrief, we know that every designer's journey is unique,
                and we're here to support yours. Whether you're diving into
                UX/UI, exploring the world of animation, or refining your web
                design skills, our platform is packed with project briefs that
                feel like the real deal.
              </Text>
              <Text lh="190%">
                We make it easy for you to practice, learn, and grow, all while
                building a portfolio that truly reflects your talents. Jump onto
                our site, browse through our variety of briefs – from design and
                UX to animation and website projects – and get started on
                something amazing.
              </Text>
              <Text lh="190%">
                We're not just about practice; we're about helping you create
                work that stands out and tells your story. Whether you're just
                starting out or have been in the game for a while, DesignBrief
                is your space to experiment, innovate, and take your skills to
                the next level. It's more than just a tool – it's a community
                where creativity thrives.
              </Text>
              <Text lh="190%">
                So, come and explore. With DesignBrief, your next big project is
                just a click away.
              </Text>
            </Stack>
          </Card>
          <Card withBorder radius="xl" p="xl" ta="left" mt={64}>
            <Stack
              ta="left"
              p={{
                md: "sm",
                lg: "xl",
              }}
              gap="xl">
              <Title fw={550} ta="center">
                FAQs
              </Title>
              <Accordion chevron={<IconPlus />}>
                <Accordion.Item p="sm" value="what-is-designbrief">
                  <Accordion.Control>What is DesignBrief?</Accordion.Control>
                  <Accordion.Panel ta="left">
                    <Text fw={400}>
                      DesignBrief is an online platform designed to help UX/UI
                      designers, graphic designers, and animators enhance their
                      skills and build standout portfolios. We provide a wide
                      variety of project briefs that mirror real-world
                      scenarios, allowing designers to practice and grow in
                      areas like UX, UI, animation, and website design.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item p="sm" value="how-does-designbrief-work">
                  <Accordion.Control>
                    How does DesignBrief work?
                  </Accordion.Control>
                  <Accordion.Panel ta="left">
                    <Text fw={400}>
                      DesignBrief works by offering a range of project briefs
                      that you can select based on your area of interest,
                      whether it's UX/UI, animation, or web design. Simply
                      choose a brief, follow the guidelines, and start working
                      on your project. Our platform is designed to simulate
                      real-world design challenges, helping you to improve your
                      skills and create portfolio-worthy work.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item p="sm" value="who-is-designbrief-for">
                  <Accordion.Control>Who is DesignBrief for?</Accordion.Control>
                  <Accordion.Panel ta="left">
                    <Text fw={400}>
                      DesignBrief is perfect for both aspiring and experienced
                      designers. Whether you're a beginner looking to develop
                      your skills in UX/UI design, a seasoned professional
                      exploring new areas like animation, or anyone in between,
                      our platform offers briefs that can help you grow and
                      excel in your design journey.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item
                  p="sm"
                  value="what-types-of-project-briefs-are-available">
                  <Accordion.Control>
                    What types of project briefs are available on DesignBrief?
                  </Accordion.Control>
                  <Accordion.Panel ta="left">
                    <Text fw={400}>
                      On DesignBrief, you'll find a diverse selection of project
                      briefs covering UX/UI design, graphic design, animation,
                      website design, and more. Each brief is crafted to
                      challenge your creativity and simulate real-world
                      projects, making it a great way to practice and build a
                      robust portfolio.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item
                  p="sm"
                  value="can-i-use-my-designs-for-my-portfolio">
                  <Accordion.Control>
                    Can I use the designs I create on DesignBrief for my
                    portfolio?
                  </Accordion.Control>
                  <Accordion.Panel ta="left">
                    <Text fw={400}>
                      Absolutely! One of the main goals of DesignBrief is to
                      help you build a strong portfolio. The projects you
                      complete using our briefs are yours to showcase in your
                      portfolio, helping you demonstrate your skills and
                      creativity to potential employers or clients.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item
                  p="sm"
                  value="how-can-i-get-started-with-designbrief">
                  <Accordion.Control>
                    How can I get started with DesignBrief?
                  </Accordion.Control>
                  <Accordion.Panel ta="left">
                    <Text fw={400}>
                      Getting started with DesignBrief is easy! Simply visit our
                      website, create an account, and browse through the
                      available project briefs. Choose a brief that matches your
                      interests, and start working on your project right away.
                      Whether you're focusing on UX/UI design, animation, or web
                      design, DesignBrief is here to support your creative
                      journey.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item p="sm" value="is-designbrief-free">
                  <Accordion.Control>
                    Is DesignBrief free to use?
                  </Accordion.Control>
                  <Accordion.Panel ta="left">
                    <Text fw={400}>
                      DesignBrief offers both free and premium subscription
                      options. Free users have access to randomly generated
                      briefs, while premium subscribers can request on-demand,
                      more detailed briefs tailored to their needs. You can
                      choose the option that best suits your goals and budget.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item
                  p="sm"
                  value="how-does-designbrief-help-improve-my-skills">
                  <Accordion.Control>
                    How does DesignBrief help improve my design skills?
                  </Accordion.Control>
                  <Accordion.Panel ta="left">
                    <Text fw={400}>
                      DesignBrief helps improve your design skills by providing
                      you with realistic project briefs that mimic actual design
                      challenges in areas like UX/UI, animation, and website
                      design. By working through these briefs, you can practice
                      your design process, experiment with new techniques, and
                      receive valuable experience that translates directly into
                      your professional work.
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Stack>
          </Card>
        </Container>
      </Stack>
    </Stack>
  );
};

export default HomePage;
