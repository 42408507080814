import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import axios from "axios";
import { User } from "./types/types";

interface AuthContextProps {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  register: (
    username: string,
    email: string,
    password: string
  ) => Promise<void>;
  logout: () => void;
  setUser: (user: User) => void;
  createCheckoutSession: (priceId: string) => Promise<void>;
  refreshUser: () => Promise<void>;
  loading: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Load user on initial load if session exists
  useEffect(() => {
    const loadUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/me`,
          { withCredentials: true } // Ensure cookies are sent with the request
        );
        if (response.data.user) {
          setUser(response.data.user);
        }
      } catch (error) {
        console.error("Failed to load user:", error);
      } finally {
        setLoading(false);
      }
    };

    loadUser();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        { email, password },
        { withCredentials: true } // Ensure cookies are sent with the request
      );
      if (response.data.user) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.error("Login failed:", error);
      if ((error as any).response && (error as any).response.status === 400) {
        throw new Error("Invalid email or password. Please try again.");
      } else {
        throw new Error(
          "An unexpected error occurred. Please try again later."
        );
      }
    }
  };

  const register = async (
    username: string,
    email: string,
    password: string
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        { username, email, password },
        { withCredentials: true } // Ensure cookies are sent with the request
      );
      if (response.data.user) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.error("Registration failed:", error);
      throw new Error("Registration failed");
    }
  };

  const logout = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/logout`,
        {},
        { withCredentials: true } // Ensure cookies are sent with the request
      );
      setUser(null);
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const createCheckoutSession = async (priceId: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/stripe/create-checkout-session`,
        { priceId },
        { withCredentials: true }
      );
      if (response.data.id) {
        window.location.href = response.data.id; // Redirect to Stripe Checkout
      }
    } catch (error) {
      console.error("Checkout session creation failed:", error);
      throw new Error("Checkout session creation failed");
    }
  };

  const refreshUser = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/auth/me`,
        { withCredentials: true }
      );
      if (response.data.user) {
        setUser(response.data.user);
      }
    } catch (error) {
      console.error("Failed to refresh user:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        register,
        logout,
        createCheckoutSession,
        refreshUser,
        loading,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
