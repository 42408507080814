import {
  Anchor,
  AspectRatio,
  BackgroundImage,
  Button,
  Card,
  Center,
  Container,
  Divider,
  Grid,
  Group,
  Image,
  List,
  Paper,
  rem,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconCheck, IconCircleCheck, IconSparkles } from "@tabler/icons-react";
import { FC } from "react";
import RegisterForm from "../Logins/Register";

const currentUser = false;

const ProfilePage: React.FC = ({}) => {
  return (
    <Stack gap={0}>
      <Container w={"100%"} size={"82rem"}>
        <Grid>
          <Grid.Col span={4}>
            <Paper withBorder>
              <Stack>
                <Title>Hello</Title>
              </Stack>
            </Paper>
          </Grid.Col>
          <Grid.Col span={8}>
            <Paper withBorder>
              <Stack>
                <Title>Hello</Title>
              </Stack>
            </Paper>
          </Grid.Col>
        </Grid>
      </Container>
    </Stack>
  );
};

export default ProfilePage;
