import React, { useState } from "react";
import {
  Anchor,
  Button,
  Card,
  Center,
  Container,
  Grid,
  Stack,
  Text,
  TextInput,
  Title,
  Select,
} from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";

const UploadBriefForm: React.FC = () => {
  const [formData, setFormData] = useState({
    projectType: "",
    briefTitle: "",
    platform: "",
    projectSize: "",
    industry: "",
    difficulty: "",
    description: "",
  });
  const [jsonFile, setJsonFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (field: string) => (value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setJsonFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!formData.projectType || !jsonFile) {
      setError("Please select a project type and upload a JSON file.");
      return;
    }

    const uploadData = new FormData();
    uploadData.append("projectType", formData.projectType);
    uploadData.append("jsonFile", jsonFile);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/briefs/upload`,
        {
          method: "POST",
          body: uploadData,
          credentials: "include",
        }
      );

      if (response.ok) {
        setSuccess("Brief uploaded successfully!");
        setError("");
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to upload brief.");
      }
    } catch (error) {
      console.error("Error uploading brief:", error);
      setError("Error uploading brief.");
    }
  };

  return (
    <Container
      h={"calc(100vh - 200px)"}
      w={{
        sm: "100%",
        md: 600,
      }}>
      <Center h="100%">
        <Card radius="lg" withBorder>
          <Stack gap="lg">
            <Title order={2}>Upload a Brief</Title>
            <Text size="lg" c="dimmed">
              Select the project type and upload your brief in JSON format
            </Text>
            <form onSubmit={handleSubmit}>
              <Grid>
                <Grid.Col span={12}>
                  <Select
                    size="lg"
                    placeholder="Select Project Type"
                    data={[
                      { value: "design", label: "Design" },
                      { value: "ux", label: "UX" },
                      { value: "ui", label: "UI" },
                      { value: "webdes", label: "Web Design" },
                      { value: "uxresearch", label: "UX Research" },
                    ]}
                    required
                    onChange={(value) =>
                      handleChange("projectType")(value || "")
                    }
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    size="lg"
                    type="file"
                    required
                    accept=".json"
                    onChange={handleFileChange}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Button
                    leftSection={<IconUpload stroke={1} />}
                    type="submit"
                    size="lg"
                    variant="gradient"
                    gradient={{ from: "grape", to: "indigo" }}
                    fullWidth>
                    Upload Brief
                  </Button>
                </Grid.Col>
              </Grid>
              {error && <Text color="red">{error}</Text>}
              {success && <Text color="green">{success}</Text>}
            </form>
          </Stack>
        </Card>
      </Center>
    </Container>
  );
};

export default UploadBriefForm;
