import React, { useState, useEffect } from "react";
import {
  Center,
  Grid,
  Pagination,
  Paper,
  Select,
  TextInput,
  Title,
  Loader,
  Alert,
  Button,
  Group,
  Overlay,
  Stack,
  Text,
  Card,
} from "@mantine/core";
import BriefCard from "../../components/Briefs/Cards";
import {
  IconLock,
  IconLockOpen,
  IconSearch,
  IconSparkles,
} from "@tabler/icons-react";
import axios from "axios";
import { Brief, User } from "../../types/types";
import { useAuth } from "../../AuthContext";
import LockedBriefs from "./locked";
import Classes from "./index.module.scss";

const FreeBriefCollectionPage = () => {
  const [briefs, setBriefs] = useState<Brief[]>([]); // Use Brief[] to type the state
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc"); // Stricter typing
  const [projectType, setProjectType] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const { user } = useAuth();

  const specificIndices = [32, 12, 44, 4, 25];

  useEffect(() => {
    const fetchBriefs = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get<{
          briefs: Brief[];
          totalPages: number;
        }>(`${process.env.REACT_APP_API_URL}/api/briefs`, {
          params: {
            search,
            sortBy,
            sortOrder,
            projectType,
            page,
            limit: 60,
          },
        });
        setBriefs(response.data.briefs);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching briefs:", error);
        setError("Failed to fetch briefs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBriefs();
  }, [search, sortBy, sortOrder, projectType, page]);

  return (
    <Grid gutter="md">
      <Grid.Col span={12}>
        <Grid align="center">
          <Grid.Col span={"auto"}>
            <Title order={2} fw={500}>
              All Briefs
            </Title>
          </Grid.Col>
          <Grid.Col span={"auto"}>
            <Group justify="right">
              <Button
                onClick={() => (window.location.href = "/subscribe")}
                variant="gradient"
                size="md"
                w="fit-content"
                gradient={{ deg: 45, from: "orange.7", to: "red.8" }}
                leftSection={<IconSparkles size={16} />}>
                Unlock All Briefs
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      {loading ? (
        <Center>
          <Loader size="lg" />
        </Center>
      ) : error ? (
        <Grid.Col span={12}>
          <Alert title="Error" color="red">
            {error}
          </Alert>
        </Grid.Col>
      ) : (
        [3, 15, 22, 37, 45, 50, 53, 59].map((index) => {
          const brief = briefs[index];
          return brief ? (
            <Grid.Col
              span={{
                lg: 3,
                md: 4,
                sm: 6,
                xs: 12,
              }}
              key={brief._id}>
              <BriefCard
                _id={brief._id}
                briefTitle={brief.brief_title}
                difficulty={brief.difficulty}
                description={brief.description}
                projectType={brief.project_type}
                createdAt={brief.createdAt}
                likes={Math.floor(Math.random() * 10000)} // Assuming random likes
              />
            </Grid.Col>
          ) : null; // Handle case where index is out of bounds
        })
      )}
      <Grid.Col
        span={12}
        pos="relative"
        h={800}
        style={{
          overflow: "hidden",
        }}>
        <Center
          top={0}
          w="100%"
          h="100%"
          pr={"sm"}
          pos="absolute"
          style={{
            zIndex: 20,
          }}>
          <Card p="xl" shadow="lg" radius="xl" withBorder>
            <Stack mr="sm" ml={0} p="md" maw={600} align="center" ta="center">
              <IconLockOpen size={64} />
              <Title order={2}>
                Unlock more detailed briefs by subscribing to our premium plan.
              </Title>
              <Text size="xl">
                Access all free & premium briefs on demand and more!
              </Text>
              <Button
                onClick={() => (window.location.href = "/subscribe")}
                variant="gradient"
                size="lg"
                gradient={{ deg: 45, from: "orange.7", to: "red.8" }}
                leftSection={<IconSparkles size={16} />}>
                Upgrade to Pro
              </Button>
            </Stack>
          </Card>
        </Center>
        <Grid.Col className={Classes.Blur} opacity={0.5} span={12}>
          <LockedBriefs />
        </Grid.Col>
        <Grid.Col className={Classes.Blurrier} opacity={0.25} span={12}>
          <LockedBriefs />
        </Grid.Col>
      </Grid.Col>
    </Grid>
  );
};

export default FreeBriefCollectionPage;
