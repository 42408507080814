import React from "react";
import {
  Badge,
  Stack,
  Text,
  Group,
  Rating,
  Button,
  Title,
  Paper,
} from "@mantine/core";

interface AIBriefProps {
  project_type: string;
  platform: string;
  type: string;
  project_size: string;
  description: string;
  difficulty: number;
  industry: string;
  brief_title: string;
}

const AIBrief: React.FC<AIBriefProps> = ({
  difficulty,
  brief_title,
  industry,
  platform,
  type,
  project_size,
  description,
}) => {
  const downloadBrief = () => {
    // Create an HTML string with the brief content
    const briefContent = `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <title>${brief_title}</title>
        <style>
          body { font-family: Arial, sans-serif; }
          .container { padding: 20px; }
          .title { font-size: 24px; font-weight: bold; margin-bottom: 10px; }
          .section { margin-bottom: 10px; }
          .difficulty { color: #FFD700; } /* Example color for difficulty */
          .badge { display: inline-block; padding: 5px 10px; margin-right: 5px; border-radius: 5px; }
          .badge.industry { background-color: #4B0082; color: white; }
          .badge.platform { background-color: #0000FF; color: white; }
          .badge.project-size { background-color: #FF4500; color: white; }
        </style>
      </head>
      <body>
        <div class="container">
          <div class="title">${brief_title}</div>
          <div class="section">
            <span>Difficulty: </span><span class="difficulty">${difficulty}</span>
          </div>
          <div class="section">
            <span class="badge industry">${industry}</span>
            <span class="badge platform">${platform}</span>
            <span class="badge project-size">${project_size}</span>
          </div>
          <div class="section">
            <span>Description: </span><div>${description}</div>
          </div>
        </div>
      </body>
      </html>
    `;

    // Create a Blob with the HTML content
    const blob = new Blob([briefContent], { type: "text/html;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    // Create a link element and click it programmatically to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = `${brief_title}.html`;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Stack w={"100%"}>
      <Group align="start" justify="space-between">
        <Stack gap="xs">
          <Title order={3}>{brief_title}</Title>
          <Group>
            <Text>Difficulty </Text>
            <Rating color="yellow.5" value={difficulty} readOnly />
          </Group>
        </Stack>
      </Group>

      <Group gap="xs">
        <Badge
          tt="capitalize"
          color="indigo"
          variant="light"
          radius="sm"
          size="lg"
        >
          {industry}
        </Badge>
        <Badge
          tt="capitalize"
          color="blue"
          variant="light"
          radius="sm"
          size="lg"
        >
          {platform}
        </Badge>

        <Badge
          tt="capitalize"
          color={
            project_size === "large"
              ? "red"
              : project_size === "medium"
              ? "orange"
              : "green"
          }
          variant="light"
          radius="sm"
          size="lg"
        >
          {project_size} difficulty
        </Badge>
      </Group>

      <Stack gap={8}>
        <Text
          dangerouslySetInnerHTML={{ __html: description }}
          size="lg"
          lh={"220%"}
        />
      </Stack>

      <Button onClick={downloadBrief}>Download Brief</Button>
    </Stack>
  );
};

export default AIBrief;
