import { useEffect, useState } from "react";
import {
  Container,
  Title,
  Text,
  Button,
  Center,
  Image,
  Loader,
  RingProgress,
  Stack,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

export default function ThankYouPage() {
  const [status, setStatus] = useState("loading"); // 'loading', 'success', 'error'
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const sessionId = searchParams.get("session_id");

    if (sessionId) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/payment/success?session_id=${sessionId}`
        )
        .then((response) => {
          setStatus("success");
          setMessage(
            "Your subscription was successful. We’re excited to have you on board!"
          );
        })
        .catch((error) => {
          console.error("Error confirming subscription:", error);
          setStatus("error");
          setMessage(
            "There was an issue confirming your subscription. Please contact support."
          );
        });
    } else {
      setStatus("error");
      setMessage("Invalid session ID. Please try again.");
    }
  }, [searchParams]);

  return (
    <Container size="sm" style={{ paddingTop: 100, textAlign: "center" }}>
      {status === "loading" ? (
        <Center>
          <Loader size="lg" />
        </Center>
      ) : status === "success" ? (
        <>
          <Stack align="center">
            <Image
              radius="xl"
              src="https://gifdb.com/images/high/dwight-the-office-birthday-celebration-jmicvbvecozh9h20.gif"
              alt="Thank You"
              mb="lg"
              maw={300}
            />
          </Stack>
          <Title order={2} mt="md">
            Thank You for Your Subscription!
          </Title>
          <Text size="lg" mt="sm" color="dimmed">
            {message}
          </Text>

          <Button
            variant="filled"
            color="teal"
            mt="xl"
            size="md"
            onClick={() => {
              window.location.href = "/briefs";
            }}>
            Go to Briefs
          </Button>
        </>
      ) : (
        <>
          <Center>
            <IconX size={60} color="red" />
          </Center>
          <Title order={2} mt="md">
            Something went wrong
          </Title>
          <Text size="lg" mt="sm" color="dimmed">
            {message}
          </Text>
          <Button
            variant="filled"
            color="red"
            mt="xl"
            size="md"
            onClick={() => {
              window.location.href = "/";
            }}>
            Go to Home
          </Button>
        </>
      )}
    </Container>
  );
}
