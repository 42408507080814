import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Stack,
  Text,
  Group,
  Center,
  Loader,
  Button,
  Divider,
  Rating,
  Title,
  Container,
  Paper,
  ThemeIcon,
  Spoiler,
  Grid,
  Space,
  Flex,
  Card,
  Blockquote,
  Timeline,
  List,
} from "@mantine/core";
import {
  IconBook,
  IconTarget,
  IconBulb,
  IconChecklist,
  IconUser,
  IconGitBranch,
  IconCircle,
} from "@tabler/icons-react";
import { Brief } from "../../../types/types"; // Adjust the path to your types

const PremiumUX: React.FC = () => {
  const { briefId } = useParams<{ briefId: string }>(); // Use useParams to get briefId
  const [brief, setBrief] = useState<Brief | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchBrief = async () => {
      console.log("Fetching brief with ID:", briefId); // Debugging log
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/briefs/premium/${briefId}`,
          {
            credentials: "include",
          }
        );
        if (response.ok) {
          const briefData = await response.json();
          setBrief(briefData);
        } else {
          const errorData = await response.json();
          console.error("Error data from backend:", errorData); // Log the error
          setError(errorData.message || "Failed to fetch the brief.");
        }
      } catch (error) {
        console.error("Error fetching brief:", error);
        setError("Error fetching the brief.");
      } finally {
        setLoading(false);
      }
    };

    fetchBrief();
  }, [briefId]);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (error) {
    return (
      <Center>
        <Text color="red">{error}</Text>
      </Center>
    );
  }

  if (!brief) {
    return (
      <Center>
        <Text>No brief found.</Text>
      </Center>
    );
  }

  return (
    <Container size="md">
      <Card p={0} radius="lg" withBorder>
        <Stack gap="lg">
          <Stack pt="xl" px="xl" gap={10}>
            <Title order={2}>{brief.brief_title}</Title>
            <Text size="lg" color="dimmed">
              {brief.description}
            </Text>
          </Stack>
          <Group px="xl" gap="md">
            <Group gap={6}>
              <Text size="sm" fw={500}>
                Platform:
              </Text>
              <Badge color="teal" variant="light" radius="xl" size="md">
                {brief.platform}
              </Badge>
            </Group>
            <Divider orientation="vertical" />
            <Group gap={6}>
              <Text size="sm" fw={500}>
                Project Size:
              </Text>
              <Badge
                variant="light"
                radius="xl"
                size="md"
                color={
                  brief.project_size === "large"
                    ? "red"
                    : brief.project_size === "medium"
                    ? "yellow"
                    : "green"
                }>
                {brief.project_size}
              </Badge>
            </Group>
            <Divider orientation="vertical" />
            <Group gap={6}>
              <Text size="sm" fw={500}>
                Difficulty:
              </Text>
              <Rating value={brief.difficulty} readOnly />
            </Group>
          </Group>

          <Divider my="sm" />

          <Stack gap="lg" px="xl">
            <Title order={3} size="h4">
              <Group gap="xs">
                <ThemeIcon variant="light" color="violet" size="lg">
                  <IconTarget size={20} />
                </ThemeIcon>
                Target Audience
              </Group>
            </Title>
            <Text size="md">
              <strong>Persona:</strong> {brief.target_audience.persona.name},{" "}
              {brief.target_audience.persona.age} years old,{" "}
              {brief.target_audience.persona.occupation}. <br />
              <strong>Tech Savviness:</strong>{" "}
              {brief.target_audience.persona.tech_savviness}.<br />
              <strong>Goals:</strong> {brief.target_audience.persona.goals}.
              <br />
              <strong>Pain Points:</strong>{" "}
              {brief.target_audience.persona.pain_points}.
            </Text>
          </Stack>
          <Divider my="lg" />
          <Stack gap="lg" px="xl">
            <Title order={3} size="h4">
              <Group gap="xs">
                <ThemeIcon variant="light" color="orange" size="lg">
                  <IconBook size={20} />
                </ThemeIcon>
                User Stories
              </Group>
            </Title>
            {brief.user_stories.map((story) => (
              <Card withBorder radius="lg" p="lg">
                <Stack gap={8}>
                  <Title order={4} fw={500}>
                    {story.title}
                  </Title>
                  <Text size="lg" color="dimmed">
                    {story.description}
                  </Text>
                </Stack>
              </Card>
            ))}
          </Stack>
          <Divider my="lg" />
          <Stack gap="lg" px="xl">
            <Title order={3} size="h4">
              <Group gap="xs">
                <ThemeIcon variant="light" color="teal" size="lg">
                  <IconChecklist size={20} />
                </ThemeIcon>
                Design Requirements
              </Group>
            </Title>
            <List spacing="sm" size="md" icon={<IconCircle size={12} />}>
              <List.Item>
                <strong>Navigation:</strong>{" "}
                {brief.design_requirements.navigation}
              </List.Item>
              <List.Item>
                <strong>Visual Design:</strong>{" "}
                {brief.design_requirements.visual_design}
              </List.Item>
              <List.Item>
                <strong>Interaction Design:</strong>{" "}
                {brief.design_requirements.interaction_design}
              </List.Item>
            </List>
          </Stack>
          <Divider my="lg" />
          <Stack gap="lg" px="xl">
            <Title order={3} size="h4">
              <Group gap="xs">
                <ThemeIcon variant="light" color="blue" size="lg">
                  <IconBulb size={20} />
                </ThemeIcon>
                Technical Requirements
              </Group>
            </Title>
            <List spacing="sm" size="md" icon={<IconCircle size={12} />}>
              <List.Item>
                <strong>Platform Support:</strong>{" "}
                {brief.technical_requirements.platform_support}
              </List.Item>
              <List.Item>
                <strong>Compatibility:</strong>{" "}
                {brief.technical_requirements.compatibility}
              </List.Item>
            </List>
          </Stack>
          <Divider my="lg" />

          <Stack gap="lg" px="xl">
            <Title order={3} size="h4">
              <Group gap="xs">
                <ThemeIcon variant="light" color="cyan" size="lg">
                  <IconChecklist size={20} />
                </ThemeIcon>
                Timeline
              </Group>
            </Title>
            <Timeline active={1} bulletSize={24} lineWidth={2}>
              {brief.timeline.milestones.map((milestone, index) => (
                <Timeline.Item
                  bullet={<IconCircle size={12} />}
                  title={milestone.phase}
                  key={index}>
                  <Text size="md" color="dimmed">
                    <strong>Deliverables:</strong>{" "}
                    {milestone.deliverables.join(", ")}
                    <br />
                    <strong>Deadline:</strong> {milestone.deadline}
                  </Text>
                </Timeline.Item>
              ))}
            </Timeline>
          </Stack>

          <Divider my="lg" />

          <Stack gap="lg" px="xl">
            <Title order={3} size="h4">
              <Group gap="xs">
                <ThemeIcon variant="light" color="green" size="lg">
                  <IconChecklist size={20} />
                </ThemeIcon>
                Success Criteria
              </Group>
            </Title>
            <Text size="md">
              <strong>User Satisfaction:</strong>{" "}
              {brief.success_criteria.user_satisfaction}.<br />
              <strong>Usability Metrics:</strong>{" "}
              {brief.success_criteria.usability_metrics}.
            </Text>
          </Stack>

          <Divider my="lg" />

          <Stack gap="lg" px="xl" pb="xl">
            <Title order={3} size="h4">
              <Group gap="xs">
                <ThemeIcon variant="light" color="red" size="lg">
                  <IconChecklist size={20} />
                </ThemeIcon>
                Notes
              </Group>
            </Title>
            <Text size="md">{brief.notes}</Text>
          </Stack>
        </Stack>
      </Card>
    </Container>
  );
};

export default PremiumUX;
