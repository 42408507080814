import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Text,
  Stack,
  Grid,
  Button,
  TextInput,
  Card,
  Title,
  PasswordInput,
  Checkbox,
  Loader,
  Center,
  List,
  rem,
  ThemeIcon,
} from "@mantine/core";
import { useAuth } from "../../../AuthContext";
import { showNotification } from "@mantine/notifications";
import { IconCircleCheck, IconSparkles } from "@tabler/icons-react";
import { loadStripe } from "@stripe/stripe-js";

const AccountSettings: React.FC = () => {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    bio: "",
    company: "",
    position: "",
    for_hire: user?.for_hire || false,
    password: "",
    confirmPassword: "",
  });
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(null);

  // Initialize Stripe outside of a component's render to avoid recreating the object on every render
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "" // Use environment variable for the public key, with a default value of an empty string
  );

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/payment/subscription-status`,
          {
            withCredentials: true,
          }
        );
        setSubscriptionStatus(response.data.subscriptionStatus);
      } catch (error) {
        console.error("Error fetching subscription status:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      // Initialize form data with user's existing data
      setFormData({
        username: user.username,
        email: user.email,
        bio: user.bio || "",
        company: user.company || "",
        position: user.position || "",
        for_hire: user.for_hire || false,
        password: "",
        confirmPassword: "",
      });

      fetchSubscriptionStatus(); // Fetch subscription status if user is logged in
    } else {
      setLoading(false); // No user, stop loading
    }
  }, [user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = async () => {
    if (formData.password !== formData.confirmPassword) {
      showNotification({
        title: "Error",
        message: "Passwords do not match",
        color: "red",
      });
      return;
    }

    setSaving(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/users/update-profile`,
        {
          ...formData,
          userId: user?._id, // Include userId in the request payload
        }
      );
      setUser(response.data); // Update context with new user data
      showNotification({
        title: "Success",
        message: "Profile updated successfully",
        color: "green",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      showNotification({
        title: "Error",
        message: "Failed to update profile",
        color: "red",
      });
    } finally {
      setSaving(false);
    }
  };

  const handleSubscription = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/payment/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            priceId: StripePaymentLink, // Replace with your actual Price ID
          }),
          credentials: "include", // Ensure cookies are sent, including the session ID
        }
      );

      const data = await response.json();

      if (data.id) {
        const stripe = await stripePromise;
        if (stripe) {
          await stripe.redirectToCheckout({ sessionId: data.id });
        } else {
          console.error("Stripe.js failed to load.");
        }
      } else {
        console.error(
          "Failed to create checkout session:",
          data.error || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error in subscription process:", error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/payment/cancel`,
        {},
        {
          withCredentials: true,
        }
      );

      console.log("Cancellation response:", response.data);

      showNotification({
        title: "Success",
        message: "Subscription canceled",
        color: "green",
      });

      if (user) {
        setUser({
          ...user,
          stripeSubscriptionId: undefined,
        });
        setSubscriptionStatus(null); // Reset subscription status
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      showNotification({
        title: "Error",
        message: "Failed to cancel subscription",
        color: "red",
      });
    }
  };

  const StripePaymentLink =
    process.env.REACT_APP_PAYMENT_LINK || "price_1PlvvQGAFEDazK0rxWdrd51V"; // Use environment variable for the payment link, with a default value of an empty string

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Container size="82rem" w={"100%"}>
      <Stack w={"100%"}>
        <Grid gutter="xl">
          <Grid.Col
            span={{
              sm: 12,
              md: 4,
            }}
          >
            <Card withBorder radius="lg">
              <Stack gap="lg">
                <Title order={4}>Account Settings</Title>
                <TextInput
                  label="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
                <TextInput
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled
                />
                <TextInput
                  label="Bio"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                />
                <TextInput
                  label="Company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
                <TextInput
                  label="Position"
                  name="position"
                  value={formData.position}
                  onChange={handleChange}
                />
                <Checkbox
                  label="Available for hire"
                  name="for_hire"
                  checked={formData.for_hire}
                  onChange={handleChange}
                />
                <PasswordInput
                  label="New Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                <PasswordInput
                  label="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <Button
                  fullWidth
                  size="md"
                  onClick={handleSave}
                  loading={saving}
                >
                  Save Changes
                </Button>
              </Stack>
            </Card>
          </Grid.Col>
          <Grid.Col
            span={{
              sm: 12,
              md: 8,
            }}
          >
            <Stack>
              <Card withBorder radius="lg">
                <Stack>
                  <Stack gap={4}>
                    <Title order={4}>Subscription Status</Title>
                    {subscriptionStatus ? (
                      <>
                        <Text>Active Subscription: Prod Brief Pro</Text>
                        <Text>
                          Next Billing Date:{" "}
                          {new Date(
                            subscriptionStatus.current_period_end * 1000
                          ).toLocaleDateString()}
                        </Text>
                        {subscriptionStatus.cancel_at_period_end && (
                          <Text color="red">
                            Your subscription is scheduled to cancel at the end
                            of this billing period.
                          </Text>
                        )}
                        {subscriptionStatus.cancel_at_period_end ? (
                          <Button
                            mt="md"
                            variant="gradient"
                            gradient={{ deg: 45, from: "teal.7", to: "cyan.8" }}
                            w="fit-content"
                            onClick={handleSubscription}
                          >
                            Renew Subscription
                          </Button>
                        ) : (
                          <Button
                            color="red"
                            variant="light"
                            size="xs"
                            mt="md"
                            w="fit-content"
                            onClick={handleCancelSubscription}
                          >
                            Cancel Subscription
                          </Button>
                        )}
                      </>
                    ) : (
                      <Stack gap="md">
                        <Stack mb={"xl"}>
                          <Stack gap="xl">
                            <Title order={2} fw={550}>
                              You're not subscribed yet!
                            </Title>
                            <List
                              spacing="md"
                              size="sm"
                              center
                              icon={
                                <IconCircleCheck
                                  style={{ width: rem(16), height: rem(16) }}
                                />
                              }
                            >
                              <List.Item>
                                <Text fw={600}>Unlock premium brief types</Text>
                              </List.Item>
                              <List.Item>
                                <Text fw={600}>
                                  Access exclusive realistic briefs like UX, UI,
                                  Website Design and more
                                </Text>
                              </List.Item>
                              <List.Item>
                                <Text fw={600}>
                                  Get early access to new features and updates
                                </Text>
                              </List.Item>
                              <List.Item>
                                <Text fw={600}>
                                  Support ProdBrief and help us keep the lights
                                  on
                                </Text>
                              </List.Item>
                              <List.Item>
                                <Text fw={600}>
                                  Earn badges and recognition for your completed
                                  briefs
                                </Text>
                              </List.Item>
                            </List>
                          </Stack>
                        </Stack>

                        <Button
                          onClick={handleSubscription}
                          leftSection={<IconSparkles size={24} stroke={1} />}
                          size="md"
                          w="fit-content"
                          variant="gradient"
                          gradient={{ deg: 45, from: "orange.7", to: "red.8" }}
                        >
                          Access All Briefs
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </Grid.Col>
        </Grid>
      </Stack>
    </Container>
  );
};

export default AccountSettings;
