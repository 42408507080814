import { Accordion, Anchor, createTheme, Paper } from "@mantine/core";
import { act } from "react";

// Define your custom theme
const customTheme = createTheme({
  colors: {
    dark: [
      "#f4f4f5", //titles
      "#b4b9c5",
      "#666B74", //Text
      "#666B74", //placeholders
      "#27272A", //borders
      "#14151a", //inputs
      "#18181B", //cards/paper
      "#0E0E11", //bg
      "#18181B", //avatar bg
      "#18181B",
    ],
  },

  primaryColor: "dark",
  defaultRadius: "md",

  components: {
    Paper: {
      defaultProps: {
        p: "xl",
      },
    },
    Anchor: {
      defaultProps: {
        c: "indigo.5",
      },
    },
    Title: {
      defaultProps: {
        fw: 550,
      },
    },
    Button: {
      defaultProps: {
        fw: 500,
        radius: "xl",
      },
    },
  },
});

export default customTheme;
