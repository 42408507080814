import React, { useState, useEffect } from "react";
import {
  ActionIcon,
  Button,
  Card,
  Grid,
  Select,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconWand } from "@tabler/icons-react";
import UXDesignBrief from "../Brief/FreeBriefs/ux";
import UIDesignBrief from "../Brief/FreeBriefs/ui";
import GraphicDesignBrief from "../Brief/FreeBriefs/graphic";
import WebsiteDesignBrief from "../Brief/FreeBriefs/web";
import UXResearchBrief from "../Brief/FreeBriefs/uxresearch";
import Classes from "./index.module.scss";
import { useAuth } from "../../../AuthContext";

const BriefForm: React.FC = () => {
  const uxBriefs = ["ux", "uxresearch"];
  const getRandomUXBrief = () =>
    uxBriefs[Math.floor(Math.random() * uxBriefs.length)];

  const [formData, setFormData] = useState({
    projectType: getRandomUXBrief(),
    industry: "All",
  });

  const [brief, setBrief] = useState<any>(null);
  const [error, setError] = useState("");
  const [industries, setIndustries] = useState<string[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchIndustries = async () => {
      if (formData.projectType) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/briefs/industries/${formData.projectType}`
          );
          if (response.ok) {
            const data = await response.json();
            setIndustries(["All", ...data]); // Add "All" option at the beginning
            setFormData((prevData) => ({
              ...prevData,
              industry: "All", // Set "All" as the default selection
            }));
          } else {
            setIndustries(["All"]);
            setFormData((prevData) => ({ ...prevData, industry: "All" }));
          }
        } catch (error) {
          console.error("Error fetching industries:", error);
          setIndustries(["All"]);
          setFormData((prevData) => ({ ...prevData, industry: "All" }));
        }
      }
    };

    fetchIndustries();
  }, [formData.projectType]);

  const handleChange = (field: string) => (value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/briefs/fetch-brief`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const fetchedBrief = await response.json();
        setBrief(fetchedBrief);
      } else {
        const errorData = await response.json();
        console.error("Server responded with error:", errorData);
        setError(errorData.message || "Failed to fetch brief.");
      }
    } catch (error) {
      console.error("Error fetching brief:", error);
      setError("Error fetching brief.");
    }
  };

  const renderBrief = () => {
    switch (formData.projectType) {
      case "ux":
        return <UXDesignBrief {...brief} />;
      case "ui":
        return <UIDesignBrief {...brief} />;
      case "design":
        return <GraphicDesignBrief {...brief} />;
      case "webdes":
        return <WebsiteDesignBrief {...brief} />;
      case "uxresearch":
        return <UXResearchBrief {...brief} />;
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap="lg">
        {error && <Text color="red">{error}</Text>}
        <Card radius={"xl"} withBorder p={8}>
          <Grid>
            <Grid.Col span={{ sm: 4, xs: 12 }}>
              <Select
                left="Type"
                radius={"xl"}
                variant="filled"
                size="lg"
                allowDeselect={false}
                value={formData.projectType}
                data={[
                  { value: "ux", label: "UX Design" },
                  { value: "uxresearch", label: "UX Research" },
                  { value: "ui", label: "UI Design" },
                  { value: "design", label: "Graphic Design" },
                  { value: "webdes", label: "Website Design" },
                ]}
                placeholder="Project type"
                onChange={(value) =>
                  value && handleChange("projectType")(value)
                }
              />
            </Grid.Col>
            <Grid.Col span={{ sm: 4, xs: 12 }}>
              {user ? (
                <Select
                  radius={"xl"}
                  variant="none"
                  size="lg"
                  allowDeselect={false}
                  value={formData.industry}
                  data={industries.map((industry) => ({
                    value: industry,
                    label: industry.charAt(0).toUpperCase() + industry.slice(1),
                  }))}
                  placeholder="Industry"
                  onChange={(value) => value && handleChange("industry")(value)}
                />
              ) : (
                <Tooltip label="Sign in to filter industries">
                  <Select
                    radius={"xl"}
                    variant="none"
                    disabled
                    size="lg"
                    allowDeselect={false}
                    value={formData.industry}
                    data={[{ value: "All", label: "All Industries" }]}
                  />
                </Tooltip>
              )}
            </Grid.Col>
            <Grid.Col span={{ sm: 4, xs: 12 }}>
              <Button
                leftSection={<IconWand size={20} stroke={1.45} />}
                type="submit"
                size="lg"
                fullWidth
                radius={"xl"}
                variant="gradient"
                gradient={{ from: "grape", to: "indigo" }}>
                Generate Brief
              </Button>
            </Grid.Col>
          </Grid>
        </Card>

        {brief ? (
          <Card w={"100%"} radius="xl" withBorder p={"xl"} mt="md">
            <Stack w="100%">{renderBrief()}</Stack>
          </Card>
        ) : (
          <Card w={"100%"} radius="xl" withBorder p={"xl"} mt="md">
            <Stack align="center" justify="center" h={300}>
              <ActionIcon
                className={Classes.ActionIcon}
                size={82}
                radius={100}
                variant="light"
                onClick={handleSubmit}>
                <IconWand stroke={1} size={46} />
              </ActionIcon>
              <Text size="lg">Generate a random brief</Text>
            </Stack>
          </Card>
        )}
      </Stack>
    </form>
  );
};

export default BriefForm;
