import {
  ActionIcon,
  Anchor,
  Badge,
  Card,
  Group,
  Modal,
  Rating,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconLock, IconLockAccess, IconX } from "@tabler/icons-react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Classes from "./index.module.scss";
import BriefSinglePage from "../../../pages/Briefs/SinglePage";
import { useDisclosure } from "@mantine/hooks";
import { useAuth } from "../../../AuthContext";

interface BriefCardProps {
  _id: string;
  briefTitle: string;
  likes?: number;
  coverImage?: string;
  authorColor?: string;
  description?: string;
  projectType?: string;
  createdAt?: string;
  difficulty?: number;
}

const BriefCard: React.FC<BriefCardProps> = ({
  _id,
  briefTitle,
  description,
  difficulty,
  projectType,
}) => {
  const { user } = useAuth(); // Assuming `user` contains `userId` and other info
  const [openBrief, { open, close }] = useDisclosure(false);
  const [isSaved, setIsSaved] = useState(false); // State to manage the saved status
  const [isPremium, setIsPremium] = useState(false); // State to manage premium status
  const navigate = useNavigate(); // Initialize useNavigate for redirection

  useEffect(() => {
    const fetchBriefDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/briefs/${_id}`,
          { withCredentials: true }
        );
        setIsPremium(response.data.isPremium);
      } catch (error) {
        console.error("Error fetching brief details:", error);
      }
    };

    fetchBriefDetails();
  }, [_id]);

  const handleCardClick = async () => {
    if (isPremium) {
      if (
        projectType === "UX Design" ||
        projectType === "UI Design" ||
        projectType === "Website Design"
      ) {
        navigate(`/ux/pro/${_id}`);
      } else {
        navigate(`/pro/${_id}`);
      }
    } else {
      open();
    }
  };

  return (
    <Stack h={"100%"}>
      <Modal
        withCloseButton={false}
        radius="lg"
        size={"xl"}
        overlayProps={{
          blur: 5,
        }}
        shadow="xl"
        opened={openBrief}
        onClose={close}
        centered>
        <ActionIcon pos="absolute" top={12} right={12} onClick={close}>
          <IconX size={24} />
        </ActionIcon>
        <BriefSinglePage briefId={_id} modalToggle={close} />
      </Modal>
      <Card
        p="xl"
        className={Classes.Card}
        h="100%"
        onClick={handleCardClick} // Use the handleCardClick function
        radius="xl"
        withBorder>
        <Stack
          h="100%"
          justify="space-between"
          w="100%"
          gap={"md"}
          align="space-between">
          <Stack gap="xl" w="100%">
            <Group justify="space-between">
              <Badge
                tt={"capitalize"}
                fw={500}
                size="sm"
                variant="light"
                color={
                  projectType === "Website Design"
                    ? "teal"
                    : projectType === "UI Design"
                    ? "blue"
                    : projectType === "UX Design"
                    ? "indigo"
                    : projectType === "Graphic Design"
                    ? "red"
                    : "gray"
                }>
                {projectType}
              </Badge>
              <Group justify="right" gap={4}>
                <Text c="dimmed" size="xs">
                  Difficulty
                </Text>
                <Rating color="yellow.5" value={difficulty} />
              </Group>
            </Group>

            <Stack gap="xs">
              {isPremium && (
                <Badge
                  leftSection={<IconLock size={12} />}
                  mb="md"
                  color="orange"
                  variant="light"
                  size="sm">
                  Pro
                </Badge>
              )}
              <Title order={2} lineClamp={2} fw={550}>
                {briefTitle}
              </Title>

              <Text size="md" lineClamp={3} lh="180%" opacity={0.65}>
                {description}
              </Text>
            </Stack>
          </Stack>
          <Group justify="apart">
            <Anchor c="dimmed" mt="md" underline="never">
              View Brief
            </Anchor>
          </Group>
        </Stack>
      </Card>
    </Stack>
  );
};

export default BriefCard;
