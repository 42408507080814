import { Text, Title, Container, Stack } from "@mantine/core";

function TermsAndConditions() {
  return (
    <Container>
      <Stack>
        <Title order={1}>Terms and Conditions</Title>
        <Text>Last Updated: 19 August, 2024</Text>

        <Text>
          Welcome to DesignBrief.io! These Terms and Conditions ("Terms") govern
          your use of our website, services, and any related products, including
          the generation of design briefs and subscription services. By
          accessing or using our services, you agree to be bound by these Terms.
          If you do not agree with these Terms, please do not use our services.
        </Text>

        <Title order={3} mt="md">
          1. Services Provided
        </Title>
        <Text>
          DesignBrief.io offers randomly generated design briefs for free users
          and on-demand, more detailed briefs for paying subscribers. Users can
          also post their projects, including images and text, on our website.
        </Text>

        <Title order={3} mt="md">
          2. User Accounts
        </Title>
        <Text>
          To access certain features of the site, you may be required to create
          an account. You agree to provide accurate and complete information
          during the registration process and to update this information as
          needed. You are responsible for maintaining the confidentiality of
          your account and password and for restricting access to your account.
        </Text>

        <Title order={3} mt="md">
          3. Subscriptions and Payments
        </Title>
        <Text>
          DesignBrief.io offers subscription services through Stripe. By
          subscribing, you agree to pay the subscription fees in accordance with
          the pricing structure outlined on the site. Payments will be processed
          through Stripe, and you are subject to Stripe's terms and conditions.
        </Text>
        <Text>
          Subscriptions are billed on a recurring basis, and you may cancel your
          subscription at any time. If you cancel, your access to premium
          features will continue until the end of the current billing cycle,
          after which your access will revert to free-tier features.
        </Text>

        <Title order={3} mt="md">
          4. Content Posted by Users
        </Title>
        <Text>
          Users may post their projects, including images and text, to the site.
          By posting content, you grant DesignBrief.io a non-exclusive,
          worldwide, royalty-free, sublicensable, and transferable license to
          use, reproduce, modify, publish, and display your content in
          connection with our services.
        </Text>
        <Text>
          You agree not to post content that is unlawful, offensive, or violates
          the rights of others. DesignBrief.io reserves the right to remove any
          content that violates these Terms or is otherwise objectionable.
        </Text>

        <Title order={3} mt="md">
          5. Use of the Service
        </Title>
        <Text>
          You agree to use the service only for lawful purposes and in a way
          that does not infringe the rights of, restrict, or inhibit anyone
          else's use and enjoyment of the service. Prohibited activities
          include, but are not limited to, harassment, causing distress or
          inconvenience to any person, transmitting obscene or offensive
          content, or disrupting the normal flow of dialogue within the service.
        </Text>

        <Title order={3} mt="md">
          6. Intellectual Property
        </Title>
        <Text>
          All content on DesignBrief.io, including design briefs, text, images,
          and logos, is the intellectual property of DesignBrief.io or its
          licensors. You may not use, copy, reproduce, distribute, or create
          derivative works from any content on the site without express
          permission.
        </Text>

        <Title order={3} mt="md">
          7. Disclaimer of Warranties
        </Title>
        <Text>
          DesignBrief.io provides the service on an "as is" and "as available"
          basis. We do not warrant that the service will be uninterrupted,
          secure, or error-free.
        </Text>

        {/* Add more sections as needed */}
      </Stack>
    </Container>
  );
}

export default TermsAndConditions;
