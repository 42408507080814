import { Text, Title, Container, List, Stack } from "@mantine/core";

function CookiePolicy() {
  return (
    <Container>
      <Stack>
        <Title order={1}>Cookie Policy</Title>
        <Text>Last Updated: 19 August, 2024</Text>

        <Text>
          At DesignBrief.io, we use cookies and similar tracking technologies to
          enhance your experience on our website, analyze site usage, and assist
          with our marketing efforts. This Cookie Policy explains what cookies
          are, how we use them, and your choices regarding their use.
        </Text>

        <Title order={3} mt="md">
          1. What Are Cookies?
        </Title>
        <Text>
          Cookies are small text files that are stored on your device when you
          visit a website. They are widely used to make websites work more
          efficiently and to provide information to the website owners. Cookies
          can be "persistent" or "session" cookies.
        </Text>

        <Title order={3} mt="md">
          2. How We Use Cookies
        </Title>
        <Text>We use cookies for various purposes, including:</Text>
        <List withPadding>
          <List.Item>
            <strong>Essential Cookies:</strong> These cookies are necessary for
            the proper functioning of our website. They allow you to navigate
            the site and use its features, such as accessing secure areas.
          </List.Item>
          <List.Item>
            <strong>Performance and Analytics Cookies:</strong> These cookies
            help us understand how visitors interact with our website by
            collecting and reporting information anonymously. We use Google
            Analytics and Microsoft Clarity for this purpose.
          </List.Item>
          <List.Item>
            <strong>Functionality Cookies:</strong> These cookies allow our
            website to remember choices you make (such as your username,
            language, or region) and provide enhanced, more personalized
            features.
          </List.Item>
          <List.Item>
            <strong>Targeting and Advertising Cookies:</strong> These cookies
            are used to deliver advertisements more relevant to you and your
            interests. They may also limit the number of times you see an ad and
            help measure the effectiveness of advertising campaigns.
          </List.Item>
        </List>

        <Title order={3} mt="md">
          3. Cookies Used on Our Site
        </Title>
        <Text>Below is a list of the cookies we use on our site:</Text>
        <List withPadding>
          <List.Item>
            <strong>Google Analytics:</strong> Used to collect information about
            how visitors use our site. This information is used to compile
            reports and help us improve the site. The cookies collect
            information anonymously, including the number of visitors to the
            site, where visitors have come from, and the pages they visited.
          </List.Item>
          <List.Item>
            <strong>Microsoft Clarity:</strong> Used to capture user
            interactions on our website. This data helps us to improve the user
            experience by understanding how users navigate our site. Clarity
            cookies do not collect personal data and anonymize user data.
          </List.Item>
          <List.Item>
            <strong>Stripe:</strong> Used to process payments on our site.
            Stripe uses cookies to remember your login details and provide a
            secure payment process.
          </List.Item>
          {/* Add any other cookies you might use */}
        </List>

        <Title order={3} mt="md">
          4. Your Cookie Choices
        </Title>
        <Text>
          You can control and manage cookies in various ways. Most browsers
          allow you to refuse or accept cookies, delete cookies, or be notified
          when you receive a cookie. However, please note that if you block
          cookies, some features of our site may not function correctly.
        </Text>
        <Text>
          <strong>Managing Cookies in Your Browser:</strong> You can manage
          cookie settings through your web browser's settings. Here are some
          links to help you manage your cookie settings:
        </Text>
        <List withPadding>
          <List.Item>
            <a
              href="https://support.google.com/chrome/answer/95647"
              target="_blank"
              rel="noopener noreferrer">
              Google Chrome
            </a>
          </List.Item>
          <List.Item>
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
              target="_blank"
              rel="noopener noreferrer">
              Mozilla Firefox
            </a>
          </List.Item>
          <List.Item>
            <a
              href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noopener noreferrer">
              Microsoft Edge
            </a>
          </List.Item>
          <List.Item>
            <a
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              target="_blank"
              rel="noopener noreferrer">
              Apple Safari
            </a>
          </List.Item>
        </List>

        <Title order={3} mt="md">
          5. Changes to This Cookie Policy
        </Title>
        <Text>
          We may update this Cookie Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We will notify you of any material changes by posting the new
          Cookie Policy on our site and updating the "Last Updated" date.
        </Text>

        <Title order={3} mt="md">
          6. Contact Us
        </Title>
        <Text>
          If you have any questions or concerns about this Cookie Policy, please
          contact us at{" "}
          <a href="mailto:contact@designbrief.io">contact@designbrief.io</a>.
        </Text>
      </Stack>
    </Container>
  );
}

export default CookiePolicy;
