import {
  AspectRatio,
  Avatar,
  BackgroundImage,
  Card,
  Group,
  Image,
  Paper,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { IconHeart, IconHeartFilled, IconRefresh } from "@tabler/icons-react";
import Classes from "./index.module.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { Post } from "../../../../types/types";
import { useAuth } from "../../../../AuthContext";

const PostsCard: React.FC<Post> = ({
  _id,
  avatar,
  title,
  author,
  coverImage,
  remixes = 0,
  likedBy = [],
}) => {
  const { user } = useAuth(); // Get the current logged-in user
  const [likesCount, setLikesCount] = useState(likedBy.length); // Initialize with the number of likes
  const [liked, setLiked] = useState(false); // Initialize as false

  // Check if the current user has liked the post
  useEffect(() => {
    if (user && likedBy.includes(user._id)) {
      setLiked(true);
    }
  }, [user, likedBy]);

  const handleLike = async (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent card click
    try {
      if (liked) {
        // Unlike the post
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/posts/unlike/${_id}`,
          {},
          { withCredentials: true }
        );
        setLikesCount((prevCount) => prevCount - 1);
        setLiked(false);
      } else {
        // Like the post
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/posts/like/${_id}`,
          {},
          { withCredentials: true }
        );
        setLikesCount((prevCount) => prevCount + 1);
        setLiked(true);
      }
    } catch (error) {
      console.error("Error liking/unliking post:", error);
    }
  };

  return (
    <Stack gap={10}>
      <Card
        onClick={() => {
          window.location.href = `/post/${_id}`;
        }}
        className={Classes.Card}
        radius="md"
        p={0}
      >
        <AspectRatio ratio={5 / 3}>
          <Image src={`${process.env.REACT_APP_API_URL}/${coverImage}`} />
        </AspectRatio>
        <Paper
          h={"100%"}
          w={"100%"}
          className={Classes.CardTitle}
          p={"lg"}
          pos="absolute"
          bottom={0}
        >
          <Stack justify="end" h={"100%"}>
            <Title order={4} fw={400} lineClamp={1}>
              {title}
            </Title>
          </Stack>
        </Paper>
      </Card>
      <Group wrap="nowrap" gap={8} justify="space-between">
        <Group
          gap={6}
          onClick={() => {
            if (!user) {
              window.location.href = `/login`;
            }
          }}
        >
          <Tooltip label={liked ? "Unlike" : "Like"}>
            {liked ? (
              <IconHeartFilled
                color="red"
                className={Classes.LikeButton}
                size={16}
                onClick={handleLike}
              />
            ) : (
              <IconHeart
                className={Classes.LikeButton}
                size={16}
                onClick={handleLike}
                style={{ color: liked ? "red" : "gray", cursor: "pointer" }}
              />
            )}
          </Tooltip>
          <Text size="xs">{likesCount}</Text>{" "}
          {/* Show the dynamic like count */}
          <Tooltip label="Remix">
            <IconRefresh className={Classes.RemixButton} size={16} />
          </Tooltip>
          <Text size="xs">{remixes}</Text>
        </Group>
      </Group>
    </Stack>
  );
};

export default PostsCard;
