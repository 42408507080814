import React, { useState, useEffect } from "react";
import {
  Center,
  Grid,
  Pagination,
  Paper,
  Select,
  TextInput,
  Title,
  Loader,
  Alert,
  Button,
  Group,
  Overlay,
} from "@mantine/core";
import BriefCard from "../../components/Briefs/Cards";
import { IconSearch, IconSparkles } from "@tabler/icons-react";
import axios from "axios";
import { Brief, User } from "../../types/types";
import { useAuth } from "../../AuthContext";

const LockedBriefs = () => {
  const [briefs, setBriefs] = useState<Brief[]>([]); // Use Brief[] to type the state
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc"); // Stricter typing
  const [projectType, setProjectType] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const { user } = useAuth();

  const specificIndices = [32, 12, 44, 4, 25];

  useEffect(() => {
    const fetchBriefs = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get<{
          briefs: Brief[];
          totalPages: number;
        }>(`${process.env.REACT_APP_API_URL}/api/briefs`, {
          params: {
            search,
            sortBy,
            sortOrder,
            projectType,
            page,
            limit: 60,
          },
        });
        setBriefs(response.data.briefs);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching briefs:", error);
        setError("Failed to fetch briefs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBriefs();
  }, [search, sortBy, sortOrder, projectType, page]);

  return (
    <Grid gutter="md">
      <Grid.Col span={12}></Grid.Col>
      {loading ? (
        <Center>
          <Loader size="lg" />
        </Center>
      ) : error ? (
        <Grid.Col span={12}>
          <Alert title="Error" color="red">
            {error}
          </Alert>
        </Grid.Col>
      ) : (
        [8, 12, 42, 13].map((index) => {
          const brief = briefs[index];
          return brief ? (
            <Grid.Col
              span={{
                lg: 3,
                md: 4,
                sm: 6,
                xs: 12,
              }}
              key={brief._id}>
              <BriefCard
                _id={brief._id}
                briefTitle={brief.brief_title}
                difficulty={brief.difficulty}
                description={brief.description}
                projectType={brief.project_type}
                createdAt={brief.createdAt}
                likes={Math.floor(Math.random() * 10000)} // Assuming random likes
              />
            </Grid.Col>
          ) : null; // Handle case where index is out of bounds
        })
      )}
    </Grid>
  );
};

export default LockedBriefs;
