import React, { useState } from "react";
import {
  Anchor,
  Button,
  Card,
  Center,
  Container,
  Grid,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconLogin } from "@tabler/icons-react";
import { Helmet } from "react-helmet";

const LoginForm: React.FC = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (field: string) => (value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
          credentials: "include", // Ensure cookies (including session ID) are sent with the request
        }
      );

      if (response.ok) {
        const user = await response.json();
        setSuccess(`Logging in...`);
        // Redirect to the dashboard after successful login
        window.location.href = "/";
      } else {
        const errorData = await response.json();
        console.error("Server responded with error:", errorData);
        setError(errorData.message || "Failed to log in.");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Error logging in.");
    }
  };

  return (
    <Container
      h={"calc(100vh - 200px)"}
      w={{
        sm: "100%",
        md: 600,
      }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DesignBrief.io - Sign In</title>
        <meta
          name="description"
          content="Hone your design skills with DesignBrief.io. Generate practice briefs for UI/UX, web design, animation, and more. Perfect for students, professionals, and teams looking to sharpen their skills."
        />
        <link rel="canonical" href="https://www.designbrief.io/login" />

        <meta
          property="og:title"
          content="DesignBrief.io - Practice Design Briefs for UI, UX, Animation & More"
        />
        <meta
          property="og:description"
          content="Create and practice with realistic design briefs tailored for UI/UX, web design, animation, and more. Improve your design skills with DesignBrief.io."
        />
        <meta property="og:url" content="https://www.designbrief.io/login" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.designbrief.io/static/designbrief-social-preview.jpg"
        />
      </Helmet>

      <Center h="100%">
        <Card radius="lg" withBorder>
          <Stack h={"00%"}>
            <Stack gap={6}>
              <Title order={2}>Log in to your account</Title>
              <Anchor href="/register">
                Don't have an account? Create one here
              </Anchor>
            </Stack>
            <form onSubmit={handleSubmit}>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    size="lg"
                    placeholder="Email"
                    required
                    onChange={(e) =>
                      handleChange("email")(e.currentTarget.value)
                    }
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    size="lg"
                    type="password"
                    placeholder="Password"
                    required
                    onChange={(e) =>
                      handleChange("password")(e.currentTarget.value)
                    }
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Button
                    leftSection={<IconLogin stroke={1} />}
                    type="submit"
                    size="lg"
                    variant="gradient"
                    gradient={{ from: "grape", to: "indigo" }}
                    fullWidth>
                    Log In
                  </Button>
                </Grid.Col>
              </Grid>
              {error && <Text color="red">{error}</Text>}
              {success && <Text color="green">{success}</Text>}
            </form>

            <Anchor href="/request-password-reset">
              Forgot your password?
            </Anchor>
          </Stack>
        </Card>
      </Center>
    </Container>
  );
};

export default LoginForm;
