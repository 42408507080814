import React, { useState } from "react";
import {
  Modal,
  Button,
  TextInput,
  Textarea,
  FileInput,
  MultiSelect,
  Group,
  LoadingOverlay,
  Stack,
  rem,
  Text,
  Input,
  ScrollArea,
  Image,
  BackgroundImage,
  AspectRatio,
  Box,
} from "@mantine/core";
import axios from "axios";
import { useAuth } from "../../../AuthContext";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconPhoto, IconUpload, IconX } from "@tabler/icons-react";

const tagsOptions = [
  "Design",
  "Development",
  "UI/UX",
  "Marketing",
  "Art",
  "Photography",
  "Writing",
  "Music",
  "Business",
  "Finance",
];

const CreatePostModal: React.FC<{ opened: boolean; onClose: () => void }> = ({
  opened,
  onClose,
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [description, setDescription] = useState("");
  const [imageGallery, setImageGallery] = useState<File[]>([]);
  const [postContent, setPostContent] = useState("");

  const handleSubmit = async () => {
    setLoading(true); // Show loading overlay
    const formData = new FormData();
    formData.append("title", title);
    if (coverImage) formData.append("coverImage", coverImage);
    formData.append("tags", JSON.stringify(tags)); // Pass tags as a JSON string
    formData.append("description", description);
    imageGallery.forEach((image) => formData.append("imageGallery", image));
    formData.append("postContent", postContent);
    formData.append("author", user?._id as string);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/posts/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      onClose(); // Close the modal after successful creation
    } catch (error) {
      console.error("Error creating post:", error);
    } finally {
      setLoading(false); // Hide loading overlay
    }
  };

  return (
    <Modal
      size={"82rem"}
      radius="xl"
      withOverlay
      overlayProps={{
        blur: 5,
      }}
      centered
      opened={opened}
      onClose={onClose}
      title="Create a New Post">
      <ScrollArea h={800} offsetScrollbars pr="sm">
        <Stack>
          <LoadingOverlay visible={loading} />
          <Input.Wrapper
            descriptionProps={{
              mb: "xs",
            }}
            size="md"
            label="Cover Image"
            description="Upload a cover image for your post">
            <Dropzone
              onDrop={(files) => setCoverImage(files[0])}
              onReject={(files) => console.log("rejected files", files)}
              maxSize={5 * 1024 ** 2}
              h={"auto"}
              accept={IMAGE_MIME_TYPE}>
              <Stack align="center" justify="center">
                <Dropzone.Accept>
                  <IconUpload
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-blue-6)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    style={{
                      width: rem(52),
                      height: rem(52),
                      color: "var(--mantine-color-red-6)",
                    }}
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <Stack h={"100%"} w={"100%"} align="center" justify="center">
                    {coverImage ? (
                      <AspectRatio ratio={5 / 4} w={300} h={200}>
                        <Image
                          miw={"100%"}
                          height={"100%"}
                          fit="cover"
                          radius="md"
                          src={URL.createObjectURL(coverImage)}
                        />
                      </AspectRatio>
                    ) : (
                      <Stack align="center" justify="center" h={265} gap={6}>
                        <IconPhoto
                          style={{
                            width: rem(52),
                            height: rem(52),
                            color: "var(--mantine-color-dimmed)",
                          }}
                          stroke={1.5}
                        />

                        <Text size="xl" inline>
                          Drag images here or click to select files
                        </Text>
                        <Text size="sm" c="dimmed" inline mt={7}>
                          Recommended size: 1200x800px
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                </Dropzone.Idle>
              </Stack>
            </Dropzone>
          </Input.Wrapper>
          <TextInput
            descriptionProps={{
              mb: "xs",
            }}
            size="md"
            label="Title"
            description="Give your post a catchy title"
            placeholder="Post title"
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
            required
          />

          <MultiSelect
            description="Select up to 10 tags"
            descriptionProps={{
              mb: "xs",
            }}
            size="md"
            label="Tags"
            data={tagsOptions}
            value={tags}
            onChange={setTags}
            placeholder="Select up to 10 tags"
            max={10}
          />
          <Textarea
            description="Brief description of your post"
            descriptionProps={{
              mb: "xs",
            }}
            size="md"
            label="Description"
            placeholder="Brief description of your post"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
            required
          />
          <FileInput
            description="Upload up to 4 images to create a gallery"
            descriptionProps={{
              mb: "xs",
            }}
            size="md"
            label="Image Gallery"
            placeholder="Upload up to 4 images"
            multiple
            onChange={(files) => setImageGallery(Array.from(files))}
            accept="image/*"
          />
          <Textarea
            description="Write the main content of your post here"
            descriptionProps={{
              mb: "xs",
            }}
            size="md"
            autosize
            maxRows={14}
            label="Post Content"
            placeholder="Tell the world your about your design..."
            value={postContent}
            onChange={(e) => setPostContent(e.currentTarget.value)}
            minRows={8}
            required
          />

          <Group justify="right" mt="md">
            <Button onClick={onClose} variant="outline" color="white">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="indigo" variant="filled">
              Create Post
            </Button>
          </Group>
        </Stack>
      </ScrollArea>
    </Modal>
  );
};

export default CreatePostModal;
