import React, { useState, useEffect } from "react";
import {
  Center,
  Grid,
  Pagination,
  Select,
  TextInput,
  Title,
  Loader,
  Alert,
  Skeleton,
} from "@mantine/core";
import BriefCard from "../../components/Briefs/Cards";
import { IconSearch } from "@tabler/icons-react";
import axios from "axios";
import { Brief } from "../../types/types";
import { useLocation, useNavigate } from "react-router-dom"; // Import hooks from React Router
import { Helmet } from "react-helmet";

const BriefCollectionPage = () => {
  const navigate = useNavigate(); // For navigation and updating the URL
  const location = useLocation(); // To access the current URL and query parameters

  // Parse URL search params to set initial state
  const queryParams = new URLSearchParams(location.search);
  const initialPage = parseInt(queryParams.get("page") || "1", 10);
  const initialSearch = queryParams.get("search") || "";
  const initialSortBy = queryParams.get("sortBy") || "createdAt";
  const initialSortOrder = (queryParams.get("sortOrder") || "desc") as
    | "asc"
    | "desc";
  const initialProjectType = queryParams.get("projectType") || "";

  const [briefs, setBriefs] = useState<Brief[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(initialPage);
  const [search, setSearch] = useState(initialSearch);
  const [sortBy, setSortBy] = useState<string>(initialSortBy);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">(initialSortOrder);
  const [projectType, setProjectType] = useState<string>(initialProjectType);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");

  // Reset page to 1 whenever filters change
  useEffect(() => {
    setPage(1);
  }, [search, sortBy, sortOrder, projectType]);

  // Update the URL whenever the filters or pagination state changes
  useEffect(() => {
    const params = new URLSearchParams();
    if (search) params.set("search", search);
    if (sortBy) params.set("sortBy", sortBy);
    if (sortOrder) params.set("sortOrder", sortOrder);
    if (projectType) params.set("projectType", projectType);
    params.set("page", page.toString());

    navigate(`?${params.toString()}`, { replace: true });
  }, [search, sortBy, sortOrder, projectType, page, navigate]);

  // Fetch briefs whenever the filters or pagination state changes
  useEffect(() => {
    const fetchBriefs = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get<{
          briefs: Brief[];
          totalPages: number;
        }>(`${process.env.REACT_APP_API_URL}/api/briefs`, {
          params: {
            search,
            sortBy,
            sortOrder,
            projectType,
            page,
            limit: 16,
          },
          withCredentials: true,
        });
        setBriefs(response.data.briefs);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching briefs:", error);
        setError("Failed to fetch briefs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBriefs();
  }, [search, sortBy, sortOrder, projectType, page]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          DesignBrief.io - UX, UI, Graphic Design, Animation Design Briefs
        </title>
        <meta
          name="description"
          content="Hone your design skills with DesignBrief.io. Generate practice briefs for UI/UX, web design, animation, and more. Perfect for students, professionals, and teams looking to sharpen their skills."
        />
        <link rel="canonical" href="https://www.designbrief.io" />

        <meta
          property="og:title"
          content="DesignBrief.io - Practice Design Briefs for UI, UX, Animation & More"
        />
        <meta
          property="og:description"
          content="Create and practice with realistic design briefs tailored for UI/UX, web design, animation, and more. Improve your design skills with DesignBrief.io."
        />
        <meta property="og:url" content="https://www.designbrief.io" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.designbrief.io/static/designbrief-social-preview.jpg"
        />
      </Helmet>
      <Grid gutter="lg">
        <Grid.Col span={12}>
          <Grid>
            <Grid.Col span={{ md: 6, sm: 12 }}>
              <Title order={2} fw={500}>
                Briefs Collection
              </Title>
            </Grid.Col>
            <Grid.Col span={{ md: 3, sm: 12 }}>
              <TextInput
                size="md"
                radius={"lg"}
                placeholder="Search briefs"
                leftSection={<IconSearch size={16} />}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid.Col>

            <Grid.Col span={"auto"}>
              <Select
                radius={"lg"}
                size="md"
                placeholder="Project Type"
                data={[
                  { value: "", label: "All" },
                  { value: "Graphic Design", label: "Design" },
                  { value: "UX Design", label: "UX Design" },
                  { value: "UI Design", label: "UI Design" },
                  { value: "Website Design", label: "Website Design" },
                  { value: "UX Research", label: "UX Research" },
                  { value: "Animation", label: "Animation" },
                ]}
                value={projectType}
                onChange={(value) => setProjectType(value || "")}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        {loading ? (
          <Grid w="100%">
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
            <Grid.Col span={3}>
              <Skeleton height={320} width="100%" />
            </Grid.Col>
          </Grid>
        ) : error ? (
          <Grid.Col span={12}>
            <Alert title="Error" color="red">
              {error}
            </Alert>
          </Grid.Col>
        ) : (
          briefs.map((brief) => (
            <Grid.Col
              span={{
                lg: 3,
                md: 4,
                sm: 6,
                xs: 12,
              }}
              key={brief._id}>
              <BriefCard
                _id={brief._id}
                briefTitle={brief.brief_title}
                description={brief.description}
                projectType={brief.project_type}
                difficulty={brief.difficulty}
                createdAt={brief.createdAt}
                likes={Math.floor(Math.random() * 10000)} // Assuming random likes
              />
            </Grid.Col>
          ))
        )}

        {!loading && !error && (
          <Grid.Col span={12}>
            <Center mt="lg">
              <Pagination
                total={totalPages}
                value={page}
                onChange={(newPage) => setPage(newPage)}
              />
            </Center>
          </Grid.Col>
        )}
      </Grid>
    </>
  );
};

export default BriefCollectionPage;
