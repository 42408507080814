import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Avatar,
  Text,
  Group,
  Stack,
  Grid,
  Pagination,
  Center,
  Loader,
  Card,
  Title,
  Alert,
} from "@mantine/core";
import { User, Post } from "../../types/types";
import CreatePostModal from "../Posts/CreatePost";
import { useAuth } from "../../AuthContext";
import PostsCard from "../BriefGenerator/Socials/Cards";

interface ProfileData {
  user: User;
  posts: Post[];
  totalPages: number;
}

const PublicProfile: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState(true);
  const [modalOpened, setModalOpened] = useState(false);
  const [activePage, setPage] = useState(1);
  const { user } = useAuth();
  const [posts, setPosts] = useState<Post[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchUserProfile = async (page = 1) => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/profile/${username}?page=${page}`
        );
        setProfileData(response.data);
        setPosts(response.data.posts);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setError("Error fetching user profile. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile(activePage);
  }, [username, activePage, user?._id]);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (!profileData) {
    return <Text>User not found.</Text>;
  }

  return (
    <Container py="xl" size="82rem" w={"100%"}>
      <Stack w={"100%"}>
        <Grid gutter="xl">
          <Grid.Col
            span={{
              sm: 12,
              md: 4,
            }}>
            <Card withBorder radius="lg">
              <Stack ta={"center"}>
                <Group align="center" wrap="nowrap">
                  <Stack align="center" w={"100%"} gap={4}>
                    <Avatar
                      size={"xl"}
                      tt="uppercase"
                      src={profileData.user?.avatar || "/default-avatar.png"}
                      alt={profileData.user?.username}>
                      {profileData.user?.username[0]}
                    </Avatar>
                    <Title>{profileData.user?.username}</Title>
                    <Text fw={600}>
                      {profileData.user?.position} at{" "}
                      {profileData.user?.company}
                    </Text>
                    <Text c="dimmed">{profileData.user?.bio}</Text>
                  </Stack>
                </Group>
              </Stack>
            </Card>
          </Grid.Col>
          <Grid.Col
            span={{
              sm: 12,
              md: 8,
            }}>
            <Card withBorder radius="lg">
              <Grid w={"100%"} gutter="xl">
                {loading ? (
                  <Center w={"100%"} h={600}>
                    <Loader size="lg" />
                  </Center>
                ) : error ? (
                  <Grid.Col span={12}>
                    <Alert title="Error" color="red">
                      {error}
                    </Alert>
                  </Grid.Col>
                ) : (
                  posts.map((post) => (
                    <Grid.Col
                      span={{
                        xs: 12,
                        sm: 6,
                        base: 12,
                      }}
                      key={post._id}>
                      <PostsCard {...post} avatar={profileData.user.username} />
                    </Grid.Col>
                  ))
                )}

                {!loading && !error && (
                  <Grid.Col span={12}>
                    <Center mt="lg">
                      <Pagination
                        total={totalPages}
                        onChange={(newPage) => setPage(newPage)}
                      />
                    </Center>
                  </Grid.Col>
                )}
              </Grid>
            </Card>
          </Grid.Col>
        </Grid>
        <CreatePostModal
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
        />
      </Stack>
    </Container>
  );
};

export default PublicProfile;
