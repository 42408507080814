import {
  Accordion,
  Badge,
  Card,
  Container,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import BriefForm from "../../components/BriefGenerator/Form";
import { useEffect, useState } from "react";
import { Post } from "../../types/types";
import { useAuth } from "../../AuthContext";
import axios from "axios";
import { IconPlus } from "@tabler/icons-react";
import { Helmet } from "react-helmet";
import AiBriefForm from "../../components/BriefGenerator/AiForm";

const AIPage = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<string>("createdAt");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [projectType, setProjectType] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const { user } = useAuth(); // Get the current logged-in user

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/posts`,
          {
            params: {
              search,
              sortBy,
              sortOrder,
              projectType,
              page,
              limit: 16,
            },
          }
        );

        const postsData: Post[] = response.data.posts;

        // Determine if each post is liked by the current user
        const postsWithLikeStatus = postsData.map((post) => ({
          ...post,
          likeStatus: user ? post.likedBy.includes(user._id) : false, // Determine if the post is liked by the user
        }));

        setPosts(postsWithLikeStatus);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setError("Failed to fetch posts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [search, sortBy, sortOrder, projectType, page, user]);

  return (
    <Stack gap={"xl"} py={40}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          DesignBrief.io - Design Briefs for UI, UX, Graphic Design, Animation &
          More
        </title>
        <meta
          name="description"
          content="Hone your design skills with DesignBrief.io. Generate practice briefs for UI/UX, web design, animation, and more. Perfect for students, professionals, and teams looking to sharpen their skills."
        />
        <link rel="canonical" href="https://www.designbrief.io" />

        <meta
          property="og:title"
          content="DesignBrief.io - Practice Design Briefs for UI, UX, Animation & More"
        />
        <meta
          property="og:description"
          content="Create and practice with realistic design briefs tailored for UI/UX, web design, animation, and more. Improve your design skills with DesignBrief.io."
        />
        <meta property="og:url" content="https://www.designbrief.io" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.designbrief.io/static/designbrief-social-preview.jpg"
        />
      </Helmet>
      <Container size="82rem" w={"100%"}>
        <Stack gap={64} w={"100%"}>
          <Stack w={"100%"} gap={32}>
            <Stack w="100%" align="stretch" gap={64}>
              <Stack w={"100%"} align="center" ta="center">
                <Badge
                  variant="gradient"
                  gradient={{ from: "red.6", to: "orange.4" }}
                  radius="xl"
                  fw={500}
                  lts={1.0}
                  size="lg">
                  AI Design Brief Generator
                </Badge>
                <Container>
                  <Stack>
                    <Title size={"46px"}>
                      Custom AI-Generated Design Briefs
                    </Title>
                    <Text size="xl" lh={1.9}>
                      Generate realistic design briefs for UI/UX, web design,
                      graphic design, and more. Perfect for students,
                      professionals, and teams looking to sharpen their skills.
                    </Text>
                  </Stack>
                </Container>
              </Stack>
              <AiBriefForm />
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default AIPage;
