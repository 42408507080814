import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Text,
  Image,
  Loader,
  Title,
  Stack,
  Group,
  Badge,
  Card,
  Grid,
  Paper,
  TextInput,
  Button,
  Divider,
  Avatar,
  ScrollArea,
  AspectRatio,
  Modal,
} from "@mantine/core";
import { Post, Comment } from "../../../types/types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const SinglePostPage: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newComment, setNewComment] = useState<string>("");
  const [submittingComment, setSubmittingComment] = useState<boolean>(false);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const [openImage, setImageOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageOpen = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setImageOpen(true);
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/posts/${postId}`
        );
        setPost(response.data);
        setLoading(false);
      } catch (error) {
        setError("Error fetching post");
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: scrollAreaRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [post?.comments]);

  const handleCommentSubmit = async () => {
    if (!newComment.trim() || submittingComment) return;

    setSubmittingComment(true);

    try {
      const response = await axios.post<Comment>(
        `${process.env.REACT_APP_API_URL}/api/posts/${postId}/comment`,
        { comment: newComment },
        { withCredentials: true }
      );

      // Append the new comment to the existing comments in state
      if (post) {
        setPost({
          ...post,
          comments: [...post.comments, response.data],
        });
      }

      setNewComment("");
    } catch (error) {
      console.error("Error submitting comment:", error);
    } finally {
      setSubmittingComment(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleCommentSubmit();
    }
  };

  if (loading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Text c="red">{error}</Text>
      </Container>
    );
  }

  if (!post) {
    return (
      <Container>
        <Text>Post not found</Text>
      </Container>
    );
  }

  return (
    <Container size="98rem">
      <Grid gutter="xl">
        <Grid.Col
          span={{
            sm: 12,
            md: 8,
          }}>
          <Stack gap="xl">
            <Stack gap={12}>
              <Title size={42}>{post.title}</Title>
              <Stack gap={4}>
                <Text component={Link} to={`/profile/${post.author.username}`}>
                  Published by {post.author.username}
                </Text>
                <Text size="sm" c="dimmed">
                  {dayjs(post.createdAt).fromNow()}
                </Text>
              </Stack>
              <Group>
                {post.tags.map((tag, index) => (
                  <Badge key={index}>{tag}</Badge>
                ))}
              </Group>
            </Stack>
            <Stack gap={52}>
              <AspectRatio ratio={5 / 4}>
                <Image
                  src={`${process.env.REACT_APP_API_URL}/${post.coverImage}`}
                  alt={post.title}
                  radius="lg"
                />
              </AspectRatio>

              <Text size="xl">{post.description}</Text>
              {post.imageGallery && post.imageGallery.length > 0 && (
                <Grid gutter="sm" grow justify="center">
                  {post.imageGallery.map((image, index) => (
                    <Grid.Col span={6} key={index}>
                      <Image
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleImageOpen(
                            `${process.env.REACT_APP_API_URL}/${image}`
                          )
                        }
                        height={400}
                        mah={300}
                        maw="100%"
                        src={`${process.env.REACT_APP_API_URL}/${image}`}
                        radius="md"
                      />
                    </Grid.Col>
                  ))}
                </Grid>
              )}
            </Stack>
            <Modal
              radius="lg"
              overlayProps={{
                blur: 5,
              }}
              centered
              size={"92rem"}
              opened={openImage}
              onClose={() => setImageOpen(false)}>
              {selectedImage && (
                <AspectRatio ratio={5 / 4}>
                  <Image src={selectedImage} radius="md" />
                </AspectRatio>
              )}
            </Modal>

            <Stack gap="md">
              <Text
                lh="180%"
                dangerouslySetInnerHTML={{ __html: post.postContent }}
              />
            </Stack>
          </Stack>
        </Grid.Col>
        <Grid.Col
          span={{
            sm: 12,
            md: 4,
          }}>
          <Stack gap="lg">
            <Paper withBorder p="md" radius="lg">
              <Title order={4}>
                {post.comments.length}{" "}
                {post.comments.length === 1 ? "Comment" : "Comments"}
              </Title>
              <Divider my="sm" />
              <ScrollArea h={400} viewportRef={scrollAreaRef}>
                {post.comments.length > 0 ? (
                  <Stack gap="md">
                    {post.comments.map((comment, index) => (
                      <Paper key={index} withBorder p="sm" radius="md">
                        <Group align="center" gap="sm">
                          {comment.author.avatar && (
                            <Avatar
                              src={comment.author.avatar}
                              alt={comment.author.username}
                              radius="xl"
                              size="md"
                            />
                          )}
                          <Stack gap={0} align="flex-start">
                            <Text
                              component={Link}
                              to={`/profile/${comment.author.username}`}
                              fw={500}>
                              {comment.author.username}
                            </Text>
                            <Text size="xs" c="dimmed">
                              {dayjs(comment.createdAt).fromNow()}
                            </Text>
                          </Stack>
                        </Group>
                        <Text mt="sm">{comment.comment}</Text>
                      </Paper>
                    ))}
                  </Stack>
                ) : (
                  <Text>No comments yet</Text>
                )}
              </ScrollArea>
              <Divider my="sm" />

              <TextInput
                placeholder="Add a comment"
                radius="md"
                size="md"
                value={newComment}
                onChange={(e) => setNewComment(e.currentTarget.value)}
                onKeyPress={handleKeyPress}
                rightSection={submittingComment ? <Loader size="xs" /> : null}
                rightSectionWidth={submittingComment ? 30 : 0}
              />
              <Button
                mt="sm"
                onClick={handleCommentSubmit}
                loading={submittingComment}>
                Post Comment
              </Button>
            </Paper>
          </Stack>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default SinglePostPage;
