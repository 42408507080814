import React from "react";
import {
  Anchor,
  Button,
  Container,
  Grid,
  List,
  Paper,
  rem,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  IconCircleCheck,
  IconCircleCheckFilled,
  IconSparkles,
} from "@tabler/icons-react";
import { loadStripe } from "@stripe/stripe-js";
import RegisterForm from "../Logins/Register";
import { useAuth } from "../../../AuthContext";
import { Helmet } from "react-helmet";

// Initialize Stripe outside of a component's render to avoid recreating the object on every render
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "" // Use environment variable for the public key, with a default value of an empty string
);

const StripePaymentLink = process.env.REACT_APP_PAYMENT_LINK; // Use environment variable for the payment link, with a default value of an empty string

console.log(process.env.REACT_APP_PAYMENT_LINK);

const Subscribe: React.FC = () => {
  const { user } = useAuth();

  const handleSubscription = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/payment/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            priceId: StripePaymentLink, // Replace with your actual Price ID
          }),
          credentials: "include", // Ensure cookies are sent, including the session ID
        }
      );

      const data = await response.json();

      if (data.id) {
        const stripe = await stripePromise;
        if (stripe) {
          await stripe.redirectToCheckout({ sessionId: data.id });
        } else {
          console.error("Stripe.js failed to load.");
        }
      } else {
        console.error(
          "Failed to create checkout session:",
          data.error || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error in subscription process:", error);
    }
  };

  return (
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DesignBrief.io - Subscribe to Design Breif Pro</title>
        <meta
          name="description"
          content="Hone your design skills with DesignBrief.io. Generate practice briefs for UI/UX, web design, animation, and more. Perfect for students, professionals, and teams looking to sharpen their skills."
        />
        <link rel="canonical" href="https://www.designbrief.io" />

        <meta
          property="og:title"
          content="DesignBrief.io - Practice Design Briefs for UI, UX, Animation & More"
        />
        <meta
          property="og:description"
          content="Create and practice with realistic design briefs tailored for UI/UX, web design, animation, and more. Improve your design skills with DesignBrief.io."
        />
        <meta property="og:url" content="https://www.designbrief.io" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.designbrief.io/static/designbrief-social-preview.jpg"
        />
      </Helmet>
      <Grid py={"xl"} align="start" gutter={32}>
        <Grid.Col span={12}>
          <Stack align="center">
            <Title fw={700}>Subscribe to ProdBrief Pro</Title>
            <Text>
              ProdBrief Pro is a subscription service that gives you access to
              premium features and exclusive content.
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ md: 6, sm: 12 }}>
          <Stack>
            <Paper radius={"lg"} withBorder shadow="'xl">
              <List
                spacing="md"
                size="sm"
                center
                icon={
                  <IconCircleCheckFilled
                    style={{ width: rem(20), height: rem(20) }}
                  />
                }>
                <Stack mb={"xl"}>
                  <Stack gap={6}>
                    <Title order={1}>$6.99/month</Title>
                  </Stack>
                </Stack>

                <List.Item>
                  <Text fw={600}>Unlock premium brief types</Text>
                </List.Item>
                <List.Item>
                  <Text fw={600}>
                    AI generated briefs for UI, UX, Web Design and more
                  </Text>
                </List.Item>
                <List.Item>
                  <Text fw={600}>
                    Access exclusive realistic briefs like UX, UI, Website
                    Design and more
                  </Text>
                </List.Item>
                <List.Item>
                  <Text fw={600}>
                    Get early access to new features and updates
                  </Text>
                </List.Item>
                <List.Item>
                  <Text fw={600}>
                    Support ProdBrief and help us keep the lights on
                  </Text>
                </List.Item>
              </List>
            </Paper>
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ md: 6, sm: 12 }}>
          <Paper radius={"lg"} withBorder shadow="'xl">
            {user ? (
              <>
                {!user.stripeSubscriptionId ? (
                  <Stack gap="xl">
                    <Stack gap={6}>
                      <Title order={2}>Upgrade to ProdBrief Pro</Title>
                      <Text>
                        Start your subscription to access premium features and
                        exclusive content.
                      </Text>
                    </Stack>
                    <Button
                      onClick={handleSubscription}
                      leftSection={<IconSparkles size={24} stroke={1} />}
                      size="md"
                      variant="gradient"
                      gradient={{ deg: 45, from: "teal.7", to: "cyan.8" }}>
                      Access All Briefs
                    </Button>
                  </Stack>
                ) : (
                  <Stack gap="xl">
                    <Stack gap={6}>
                      <Title order={2}>You're already subscribed!</Title>
                      <Text>
                        Your subscription is active and you have access to all
                        ProdBrief Pro features.
                      </Text>
                    </Stack>
                    <Button
                      onClick={() => {
                        // Navigate to subscription management if applicable
                        window.location.href = "/profile/settings";
                      }}
                      leftSection={<IconSparkles size={24} stroke={1} />}
                      size="md"
                      variant="gradient"
                      gradient={{ from: "grape", to: "violet" }}>
                      Subscription Settings
                    </Button>
                  </Stack>
                )}
              </>
            ) : (
              <Stack gap="xl">
                <Stack gap={6}>
                  <Title order={3}>Create an account</Title>
                  <Text>
                    Already have an account?{" "}
                    <Anchor variant="link" href="/login">
                      Log in
                    </Anchor>
                  </Text>
                </Stack>
                <RegisterForm />
              </Stack>
            )}
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Subscribe;
