import React, { useState } from "react";
import {
  Anchor,
  Button,
  Card,
  Center,
  Container,
  Grid,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconMail } from "@tabler/icons-react";

const RequestPasswordReset = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setMessage("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/request-password-reset`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      console.error("Error requesting password reset:", error);
      setMessage("Error requesting password reset.");
    }
  };

  return (
    <Container
      h={"calc(100vh - 200px)"}
      w={{
        sm: "100%",
        md: 600,
      }}
    >
      <Center h="100%">
        <Card radius="lg" withBorder>
          <Stack h={"00%"}>
            <Stack gap={6}>
              <Title order={2}>Reset Your Password</Title>
              <Text size="lg" c="dimmed">
                Enter your email to receive a password reset link
              </Text>
            </Stack>
            <form onSubmit={handleSubmit}>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    size="lg"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Button
                    leftSection={<IconMail stroke={1} />}
                    type="submit"
                    size="lg"
                    variant="gradient"
                    gradient={{ from: "grape", to: "indigo" }}
                    fullWidth
                  >
                    Request Password Reset
                  </Button>
                </Grid.Col>
              </Grid>
              {message && <Text color="green">{message}</Text>}
            </form>
            <Anchor href="/login">Remember your password? Log in here</Anchor>
          </Stack>
        </Card>
      </Center>
    </Container>
  );
};

export default RequestPasswordReset;
