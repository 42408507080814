import React from "react";
import {
  Badge,
  Stack,
  Text,
  Group,
  Rating,
  Button,
  Title,
  Divider,
} from "@mantine/core";

interface UXResearchBriefProps {
  project_type: string;
  platform: string;
  type: string;
  project_size: string;
  description: string;
  difficulty: number;
  industry: string;
  brief_title: string;
}

const UXResearchBrief: React.FC<UXResearchBriefProps> = ({
  difficulty,
  brief_title,
  industry,
  platform,
  type,
  project_size,
  description,
}) => {
  return (
    <Stack w={"100%"}>
      <Group align="start" justify="space-between">
        <Stack gap="xs">
          <Title order={3}>{brief_title}</Title>
          <Group>
            <Text>Difficulty </Text>
            <Rating color="yellow.5" value={difficulty} />
          </Group>
        </Stack>
      </Group>

      <Group gap="xs">
        <Badge tt="capitalize" color="cyan" radius="sm" size="md">
          {industry}
        </Badge>
        <Badge tt="capitalize" color="orange" radius="sm" size="md">
          {platform}
        </Badge>

        <Badge
          tt="capitalize"
          color={
            project_size === "large"
              ? "red"
              : project_size === "medium"
              ? "yellow"
              : "green"
          }
          radius="sm"
          size="md"
        >
          {project_size}
        </Badge>
      </Group>
      <Divider my="md" />
      <Stack gap={8}>
        <Title order={4}>Brief:</Title>
        <Text size="lg" lh={"190%"}>
          {description}
        </Text>
      </Stack>
    </Stack>
  );
};

export default UXResearchBrief;
